// require all needed dependencies here
define('modules/stats/carousel/js/base.js',["app/config", "jquery", "app/module", "app/util", "templates", "jquery.mobile.custom"], function (config, $, module, util, templates) {
    return new module({
        name: 'stats',
        selectors: '.statsCarouselWrap',
        remoteData: [config.urls.stats],

        initialize: function (data) {

            this.$container = $(this.getSelectors());
            this.data = data;

            this.renderTemplates(data);
            this.attachEventHandlers();
            this.setInitialState();
        },

        onRender: function () {

        },

        methods: {
            renderTemplates: function (data) {
                this.data = (data || this.data);

                this.$container.append(templates.statscarousel({
                    records: data
                }));

                // init caro
                this.$container.find('.carousel.slide')
                    .carousel({
                        interval: 7500,
                        pause: null
                    }).swipeleft(function () {
                    $(this).carousel('next');
                }).swiperight(function () {
                    $(this).carousel('prev');
                });
            },

            attachEventHandlers: function (data) {
                this.$container.on('click', $.proxy(function (event) {
                    // using jQuery's proxy function we will have access to the module using "this" keyword
                }, this));
            },

            setInitialState: function (data) {

            }
        }

    });
});
