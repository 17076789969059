define('templates',['handlebars'], function(Handlebars) {

var glob = ('undefined' === typeof window) ? global : window,

Handlebars = glob.Handlebars || require('handlebars');

this["JST"] = this["JST"] || {};

Handlebars.registerPartial("finalistsbios", this["JST"]["finalistsbios"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                                            <div class=\"finalist-full-bio-quote\">\n                                              "
    + container.escapeExpression(((helper = (helper = helpers.quote || (depth0 != null ? depth0.quote : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"quote","hash":{},"data":data}) : helper)))
    + "\n                                            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"finalist-container\" data-pagenav=\"true\" data-finalist=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"finalist-container-bg\"></div>\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-sm-12 col-md-offset-1 col-md-10 finalist-overlay-main-content\">\n\n                <div class=\"nav\">\n                  <div class=\"close-overlay\">Back to finalists</div>\n                </div>\n\n                <div class=\"inner\">\n\n                    <div class=\"finalist-content\">\n                        <div class=\"finalist-video\" video-url=\""
    + alias4(((helper = (helper = helpers.videourl || (depth0 != null ? depth0.videourl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videourl","hash":{},"data":data}) : helper)))
    + "\">\n                            <div class=\"video-iframe-container\"></div>\n                        </div>\n\n                        <div class=\"finalist-full-bio\">\n                            <div class=\"container\">\n                                <div class=\"row\">\n                                    <div class=\"col-sm-12\">\n                                        <h4>"
    + ((stack1 = ((helper = (helper = helpers.organization || (depth0 != null ? depth0.organization : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organization","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h4>\n                                        <h5>"
    + ((stack1 = ((helper = (helper = helpers.proposaltitle || (depth0 != null ? depth0.proposaltitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"proposaltitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h5>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.quote : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                        "
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n                </div>\n\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("finalistspost", this["JST"]["finalistspost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"col-xs-12 col-sm-6 col-md-4 col-lg-4 finalist-post finalist-visible\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-catid=\""
    + alias4(((helper = (helper = helpers.catId || (depth0 != null ? depth0.catId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"catId","hash":{},"data":data}) : helper)))
    + "\" data-teamid=\""
    + alias4(((helper = (helper = helpers.teamID || (depth0 != null ? depth0.teamID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"teamID","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"inner-col finalist-col\" data-index=\""
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n        <div class=\"finalists-wrap\">\n            <div class=\"finalist-thumb go-to-finalists\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ")\"></div>\n            <div class=\"finalists-info\">\n                <h3 class=\"finalists\">"
    + alias4(((helper = (helper = helpers.organization || (depth0 != null ? depth0.organization : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organization","hash":{},"data":data}) : helper)))
    + "</h3>\n                <span class=\"finalist-propsal-title\">"
    + ((stack1 = ((helper = (helper = helpers.proposaltitle || (depth0 != null ? depth0.proposaltitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"proposaltitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n                <div class=\"learn-more go-to-finalists\">Learn More</div>\n            </div>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("granteesbios", this["JST"]["granteesbios"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"finalist-container\" data-pagenav=\"true\" data-finalist=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-sm-12 finalist-overlay-main-content\">\n\n                <div class=\"nav\">\n                    <div class=\"close-overlay\">\n                        <span class=\"icon-icon-close\"></span>\n                    </div>\n                </div>\n\n                <div class=\"inner\">\n\n                    <div class=\"finalist-content\">\n                        <div class=\"finalist-video\" video-url=\""
    + alias4(((helper = (helper = helpers.granteeVideoUrl || (depth0 != null ? depth0.granteeVideoUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"granteeVideoUrl","hash":{},"data":data}) : helper)))
    + "\">\n                            <div class=\"video-iframe-container\"></div>\n                        </div>\n\n                        <div class=\"grantee-overlay-banner\">\n                          Grantee\n                        </div>\n\n                        <div class=\"finalist-full-bio\">\n                            <div class=\"container\">\n                                <div class=\"row\">\n                                    <div class=\"col-sm-12 col-md-8 col-md-offset-2\">\n                                        <span class=\"city-country\"><img src=\"img/finalist-post-marker.png\"/>"
    + alias4(((helper = (helper = helpers.location || (depth0 != null ? depth0.location : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"location","hash":{},"data":data}) : helper)))
    + "</span>\n                                        <h4>"
    + ((stack1 = ((helper = (helper = helpers.organization || (depth0 != null ? depth0.organization : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organization","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h4>\n                                        "
    + ((stack1 = ((helper = (helper = helpers.granteeDescription || (depth0 != null ? depth0.granteeDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"granteeDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n                </div>\n\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("granteespost", this["JST"]["granteespost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"col-xs-12 col-sm-6 col-md-4 col-lg-3 finalist-post finalist-visible\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-catid=\""
    + alias4(((helper = (helper = helpers.catId || (depth0 != null ? depth0.catId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"catId","hash":{},"data":data}) : helper)))
    + "\" data-teamid=\""
    + alias4(((helper = (helper = helpers.teamID || (depth0 != null ? depth0.teamID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"teamID","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"inner-col finalist-col\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n\n        <div class=\"finalists-wrap\">\n          <div class=\"grantee-banner go-to-finalists\">\n            Grantee\n          </div>\n            <div class=\"finalist-thumb\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ")\"></div>\n            <div class=\"finalists-info\">\n                <span class=\"city-country\"><img src=\"img/finalist-post-marker.png\"/>"
    + alias4(((helper = (helper = helpers.location || (depth0 != null ? depth0.location : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"location","hash":{},"data":data}) : helper)))
    + "</span>\n                <h3 class=\"finalists\">"
    + alias4(((helper = (helper = helpers.organization || (depth0 != null ? depth0.organization : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"organization","hash":{},"data":data}) : helper)))
    + "</h3>\n                <p class=\"finalist-summary\">"
    + ((stack1 = ((helper = (helper = helpers.proposaltitle || (depth0 != null ? depth0.proposaltitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"proposaltitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n                <div class=\"learn-more go-to-finalists\">Learn More</div>\n            </div>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("judgebox", this["JST"]["judgebox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"judgeBox\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n    <section class=\"judgeThumb layout-vertical-center\">\n        <img class=\"judgeThumbImg img-responsive\" src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"judgeThumbTitle\">\n            <h5>"
    + alias4((helpers.formatName || (depth0 && depth0.formatName) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"formatName","hash":{},"data":data}))
    + "</h5>\n            <h6 class=\"title\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h6>\n            <span class=\"more\">Read Bio</span>\n        </div>\n    </section>\n</a>";
},"useData":true}));

Handlebars.registerPartial("judgegroup", this["JST"]["judgegroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgebox,depth0,{"name":"judgebox","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"judgeGroup\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-xs-12 col-md-11 col-md-offset-1\">\n                <div class=\"judgeGroupHeading\">\n                    <h3 class=\"judgeHeadingTitle retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_h3\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n                </div>\n                <div class=\"judgeGroupDescription retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_content\">\n                    <p>"
    + ((stack1 = ((helper = (helper = helpers.desc || (depth0 != null ? depth0.desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"desc","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"judgesDetailContainer\">\n"
    + ((stack1 = container.invokePartial(partials.judgeinfogroup,depth0,{"name":"judgeinfogroup","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"judgeGridContainer\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</section>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("judgeinfo", this["JST"]["judgeinfo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <div class=\"judgeQuoteContainer\"><q>"
    + container.escapeExpression(((helper = (helper = helpers.quote || (depth0 != null ? depth0.quote : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"quote","hash":{},"data":data}) : helper)))
    + "</q></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeInfoContainer\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"judgeInfoImg\" style=\"background-image:url('"
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "');\"></div>\n    <div class=\"judgeInfoText\">\n        <div class=\"judgeInfoScroll\">\n            <div class=\"judgeInfoSpace\">\n                <span class=\"judgeName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n                <span class=\"judgeShortDescription\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</span>\n                <div class=\"judgeLongDescription\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.quote : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("judgeinfogroup", this["JST"]["judgeinfogroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfo,depth0,{"name":"judgeinfo","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeDetailContainer container\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"judgeInfoGroup row active\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n\n        <div class=\"col-xs-12\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"judgeDetailNav prev\"><span class=\"judgeDetailNavInner materialIcons\"></span></div>\n        <div class=\"judgeDetailNav next\"><span class=\"judgeDetailNavInner materialIcons\"></span></div>\n\n        <div class=\"judgeDetailCounter\">\n            <span class=\"currentCount\">01</span>\n            <span class=\"divider\">/</span>\n            <span class=\"totalCount\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1), depth0))
    + "</span>\n        </div>\n\n    </div>\n</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("resourcepost", this["JST"]["resourcepost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"resourcebox col-xs-12 col-sm-6 col-md-4\" data-resource=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"inner\">\n		<div class=\"postTitle layout-vertical-center\">\n			<h3><a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a></h3>\n		</div>\n		<a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" class=\"btn\" target=\"_blank\">Learn More</a>\n	</div>\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("scoringintro", this["JST"]["scoringintro"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return " <div class=\"section pushSection layout-auto-height angleBottomRight\" data-bgimage=\"scoring-intro\">\n	<div class=\"introSection container\" data-title=\"\">\n		<!--<div class=\"load_scoring\">-->\n		<div class=\"row center-height\">\n			<div class=\"col-md-10 col-md-offset-1 content load_scoring_card1\">\n				<h1>Scoring Process</h1>\n				<h2>A careful selection of judges grouped into two categories; the Evaluation Panel and the Selection Committee.</h2>\n				<a class=\"intro-section-btn\" data-scrollto=\".scoreQuestion.section\">Interact with the Rubric<i class=\"icon-icon-arrow-down-rounded\"></i></a>		\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("scoringitems", this["JST"]["scoringitems"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "						<div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n						<div class=\"tick tickBottom\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg\" data-title=\"\" data-bgimage=\"scoring-body\">\n	<div class=\"container\">\n		<div class=\"row center-height\">\n			<a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\">Leave Comments</a>\n			<h3 class=\"hidden-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n			<div class=\"text-center\">\n				<div class=\"helpText\"><div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div></div>\n				<div class=\"commentsText text-center\">\n					<textarea placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n					<div class=\"hidden-xs\">\n						<a class=\"btn\" href=\"#\">Submit Comments</a>\n					</div>\n				</div>\n			</div>\n			<div class=\"scoreHeader\">\n				<div class=\"scoreCounter hidden-xs\">\n					<span class=\"current\">"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"total\">"
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<div class=\"scoreCounter scoreNav\">\n					<span class=\"prev\"><i class=\"icon-arrow-left-full\"></i></span>\n					<span class=\"next\"><i class=\"icon-arrow-right-full\"></i></span>\n				</div>\n				<div class=\"scoreQuestion\">\n					<span class=\"visible-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"questionText\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"helpQuestion\">\n						<div class=\"icon-icon-help1\"></div>\n					</span>\n					<div class=\"icon-icon-close\"></div>\n				</div>\n			</div>\n			<div class=\"scoreDescription\">\n				<span></span>\n			</div>\n			<div class=\"scoreValue\">\n				<span class=\"valfollow\"></span>\n				<span class=\"valBorderLeft hidden-xs\"></span>\n				<span class=\"valBorderRight hidden-xs\"></span>\n			</div>\n			<div class=\"scoreSliderContainer\">\n				<div class=\"sliderTicks\">\n			    	<span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			    	<span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n				<div class=\"scoreSlider\">\n					<input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n				</div>\n				<span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n		</div>\n		<!--<a class=\"btn scoreCommentBtn primaryBlankBtn hidden-xs hidden-sm \">Leave Comments</a>-->\n	</div>\n</div>";
},"useData":true,"useDepths":true}));

Handlebars.registerPartial("scoringlastcard", this["JST"]["scoringlastcard"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"lastCard section pushSection register layout-auto-height angleTopLeft\" data-bgimage=\"endcard\">\n\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("sidebarbottommenu", this["JST"]["sidebarbottommenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n				<li><span>"
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</span></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebar-inner-list inner-closed\">\n	<span class=\"sidebar-inner-back\">\n		<div class=\"sidebar-icon pe-7s-left-arrow\"></div>\n		<div class=\"sidebar-label\">Back</div>\n	</span>\n	<ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebaritem", this["JST"]["sidebaritem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "a";
},"3":function(container,depth0,helpers,partials,data) {
    return "div";
},"5":function(container,depth0,helpers,partials,data) {
    return " sidebarItemSelected";
},"7":function(container,depth0,helpers,partials,data) {
    return " sidebarItemNestedParent";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "sidebar-neverwrap";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "		<div class=\"sidebar-item-data data-"
    + container.escapeExpression(((helper = (helper = helpers.dataName || (depth0 != null ? depth0.dataName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dataName","hash":{},"data":data}) : helper)))
    + "\"></div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebartopmenu,depth0,{"name":"sidebartopmenu","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " class=\"sidebarItem"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSelected : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.newtab : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n	<div class=\"sidebarIcon "
    + alias4(((helper = (helper = helpers.iconClass || (depth0 != null ? depth0.iconClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconClass","hash":{},"data":data}) : helper)))
    + "\"></div>\n	<div class=\"sidebarItemLabel "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebartopmenu", this["JST"]["sidebartopmenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      <div class=\"sidebarItemNestedLabel\">\n        "
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n      </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\" class=\"sidebarItem sidebarItemNested\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebarItemNestedContainer\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true}));

this["JST"]["faqcards"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "                       <div class=\"qaCardInner\" index=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\">\n                           <h3 class=\"title\">\n                               "
    + ((stack1 = ((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                               <span class=\"icon materialIcons\">keyboard_arrow_down</span>\n                            </h3>\n                           <div class=\"hidden innerSwap\">\n                               <h3 class=\"title\">"
    + ((stack1 = ((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n                               "
    + ((stack1 = ((helper = (helper = helpers.answer || (depth0 != null ? depth0.answer : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answer","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                           </div>\n                       </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-md-12\">\n            <div class=\"faqIntro retired-load_home_faq_card retired-layout-load-card\">\n                <h4>FAQs</h4>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"row-wrap expanderGrid\">\n    <div class=\"container\">\n\n        <div class=\"col-md-6\">\n            <div class=\"faqLeft\">\n               <div class=\"faqLeftInner\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "               </div>\n            </div>\n        </div>\n        <div class=\"col-md-6\">\n            <div class=\"faqRight\">\n                <div class=\"faqRightInner layout-vertical-center\">\n                    <div class=\"row detailRow\">\n                        <div class=\"expanderContent\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["finalistbio"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"finalist-winner\">Winner</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <div class=\"finalist-full-bio-quote\">\n                        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.quote : stack1), depth0))
    + "\n                      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"finalist-container active\" data-pagenav=\"true\" data-finalist=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">\n  <div class=\"finalist-container-bg\"></div>\n  <div class=\"container\">\n    <div class=\"row\">\n      <div class=\"finalist-overlay-main-content\">\n\n        <div class=\"nav\">\n            <div class=\"close-overlay\">Back to Winners &amp; Finalists</div>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.winner : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"inner\">\n\n          <div class=\"finalist-content\">\n            <div class=\"finalist-video\" video-url=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.videourl : stack1), depth0))
    + "\">\n              <div class=\"video-iframe-container\">\n                <div class=\"custom-video\" data-youtube=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.videourl : stack1), depth0))
    + "\" data-autoplay=\"0\"></div>\n              </div>\n            </div>\n\n            <div class=\"finalist-full-bio\">\n              <div class=\"container\">\n                <div class=\"row\">\n                  <div class=\"col-sm-12\">\n                    <h4>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.organization : stack1), depth0)) != null ? stack1 : "")
    + "</h4>\n                    <h5>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.proposaltitle : stack1), depth0)) != null ? stack1 : "")
    + "</h5>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.quote : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.longDescription : stack1), depth0)) != null ? stack1 : "")
    + "\n                  </div>\n                </div>\n              </div>\n            </div>\n          </div>\n\n        </div>\n\n      </div>\n    </div>\n  </div>\n\n  <div class=\"additionalFinalists\">\n    <div class=\"container-fluid\">\n      <div class=\"row\">\n\n        <div class=\"col-md-6\">\n          <div class=\"additionalFinalists__prev-item finalist-col\"\n          style=\"background-image: url('"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.prevItem : depth0)) != null ? stack1.image : stack1), depth0))
    + "')\"\n          data-index=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.prevItem : depth0)) != null ? stack1.index : stack1), depth0))
    + "\" data-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.prevItem : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">\n            <div class=\"inner\">\n              <span class=\"additionalFinalists__title\">Previous Finalist</span>\n              <span class=\"additionalFinalists__proposal-title next-prev-finalists\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.prevItem : depth0)) != null ? stack1.proposaltitle : stack1), depth0))
    + "</span>\n              <div class=\"additionalFinalists__short-description next-prev-finalists\">\n                "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.prevItem : depth0)) != null ? stack1.shortDescription : stack1), depth0)) != null ? stack1 : "")
    + "\n              </div>\n              <a href=\"#\" class=\"additionalFinalists__more-link next-prev-finalists\">Find out More</a>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"col-md-6\">\n          <div class=\"additionalFinalists__next-item finalist-col\"\n          style=\"background-image: url('"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.nextItem : depth0)) != null ? stack1.image : stack1), depth0))
    + "')\"\n          data-index=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.prevItem : depth0)) != null ? stack1.index : stack1), depth0))
    + "\" data-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.nextItem : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">\n            <div class=\"inner\">\n              <span class=\"additionalFinalists__title\">Next Finalist</span>\n              <span class=\"additionalFinalists__proposal-title next-prev-finalists\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.nextItem : depth0)) != null ? stack1.proposaltitle : stack1), depth0))
    + "</span>\n              <div class=\"additionalFinalists__short-description next-prev-finalists\">\n                "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.nextItem : depth0)) != null ? stack1.shortDescription : stack1), depth0)) != null ? stack1 : "")
    + "\n              </div>\n              <a href=\"#\" class=\"additionalFinalists__more-link next-prev-finalists\">Find out More</a>\n            </div>\n          </div>\n        </div>\n\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});

this["JST"]["finalists"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.subType : depth0),"finalist",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "\n";
  stack1 = ((helper = (helper = helpers.desktop_xl_width || (depth0 != null ? depth0.desktop_xl_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_xl_width","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_xl_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.tablet_width || (depth0 != null ? depth0.tablet_width : depth0)) != null ? helper : alias2),(options={"name":"tablet_width","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.tablet_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.xs_width || (depth0 != null ? depth0.xs_width : depth0)) != null ? helper : alias2),(options={"name":"xs_width","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.xs_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n"
    + ((stack1 = container.invokePartial(partials.finalistspost,depth0,{"name":"finalistspost","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = helpers.xs_width || (depth0 != null ? depth0.xs_width : depth0)) != null ? helper : alias2),(options={"name":"xs_width","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.xs_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.tablet_width || (depth0 != null ? depth0.tablet_width : depth0)) != null ? helper : alias2),(options={"name":"tablet_width","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.tablet_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.desktop_xl_width || (depth0 != null ? depth0.desktop_xl_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_xl_width","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_xl_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),3,{"name":"rowOpen","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                                <div class=\"row finalist-row\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),1,{"name":"rowOpen","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),1,{"name":"rowClose","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "                                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),2,{"name":"rowClose","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),3,{"name":"rowClose","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\" data-title=\"\">\n    <div class=\"row\">\n        <div class=\"col-xs-12 content\">\n            <section data-title=\"Learn\" class=\"text-center learnSection finalistsSection\">\n\n                <div class=\"row-wrap finalists\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </section>\n        </div>\n    </div>\n</div>\n\n<div class=\"finalist-overlay-grid\"></div>\n";
},"usePartial":true,"useData":true});

this["JST"]["footer"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"container container-fluid\">\n    <div class=\"row\">\n        <div class=\"col-sm-6 footerContent\">\n            <div class=\"footerSocialLinks\">\n                <a href=\"https://www.facebook.com/accessventures\" class=\"footerSocialLinksItem\" target=\"_blank\"><img src=\"/img/social/facebook.svg\" class=\"socialLinksIcon\" alt=\"Facebook\"/></a>\n                <a href=\"https://twitter.com/AcsVentures\" class=\"footerSocialLinksItem\" target=\"_blank\"><img src=\"/img/social/twitter.svg\" class=\"socialLinksIcon\" alt=\"Twitter\"/></a>\n            </div>\n            <div class=\"footerLegalLinks\">\n                <a href=\"#privacy-policy\" class=\"legalLink\">Privacy Policy</a>\n                <a href=\"#terms-conditions\" class=\"legalLink\">Terms &amp; Conditions</a>\n                <a href=\"mailto:questions@reconstructchallenge.com\" class=\"legalLink\">Contact Us</a>\n            </div>\n        </div>\n        <div class=\"col-sm-6 footerContent\">\n            <div class=\"footerCopyright\">\n                <p class=\"footerCopyrightText\">2019 Rampit &copy; Challenge designed by <br><a href=\"http://commonpool.org/\" target=\"_blank\" class=\"copyrightLink\">Common Pool</a> Powered by <a href=\"https://www.rampit.com/\" target=\"_blank\" class=\"copyrightLink\">Rampit</a></p>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["grantees"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.type : depth0),"Grantee",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "\n";
  stack1 = ((helper = (helper = helpers.desktop_xl_width || (depth0 != null ? depth0.desktop_xl_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_xl_width","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_xl_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.tablet_width || (depth0 != null ? depth0.tablet_width : depth0)) != null ? helper : alias2),(options={"name":"tablet_width","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.tablet_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.xs_width || (depth0 != null ? depth0.xs_width : depth0)) != null ? helper : alias2),(options={"name":"xs_width","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.xs_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n"
    + ((stack1 = container.invokePartial(partials.granteespost,depth0,{"name":"granteespost","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = helpers.xs_width || (depth0 != null ? depth0.xs_width : depth0)) != null ? helper : alias2),(options={"name":"xs_width","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.xs_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.tablet_width || (depth0 != null ? depth0.tablet_width : depth0)) != null ? helper : alias2),(options={"name":"tablet_width","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.tablet_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.desktop_xl_width || (depth0 != null ? depth0.desktop_xl_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_xl_width","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_xl_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),4,{"name":"rowOpen","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                                <div class=\"row finalist-row\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),3,{"name":"rowOpen","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),1,{"name":"rowOpen","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),1,{"name":"rowClose","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "                                </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),2,{"name":"rowClose","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),3,{"name":"rowClose","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.type : depth0),"Grantee",{"name":"ifCond","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = container.invokePartial(partials.granteesbios,depth0,{"name":"granteesbios","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"container\" data-title=\"\">\n    <div class=\"row\">\n        <div class=\"col-xs-12 content\">\n            <section data-title=\"Learn\" class=\"text-center learnSection finalistsSection\">\n\n                <div class=\"row-wrap finalists\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </section>\n        </div>\n    </div>\n</div>\n\n<div class=\"finalist-overlay-grid\">\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});

this["JST"]["judgespage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgegroup,depth0,{"name":"judgegroup","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"judges\" class=\"container card-container judgesCardContainer\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});

this["JST"]["lastcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n                    <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                            Only <span class=\"daysLeft\">60</span> <span class=\"dayLeftCountWord\">days</span> to submit\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                            Submissions have closed\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n                        <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </p>\n\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                                Only <span class=\"daysLeft\">60</span> <span class=\"dayLeftCountWord\">days</span> to submit\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                                Submissions have closed.\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                            <p class=\"actionTitle\">Only <span class=\"daysCount\">64</span>&nbsp;<span class=\"dayCountWord\">Days</span> left to register</p>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </p>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                    Registration has closed.\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                                    Submissions have closed.\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "    <a href=\"#rp\" class=\"lastCardRegister retire-modal-trigger\">Return to Dashboard</a>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"#login\" class=\"lastCardRegister retire-modal-trigger\">Login</a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"#register\" class=\"lastCardRegister retire-modal-trigger\">Register Today</a>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"#resources\" class=\"lastCardRegister\">Learn More</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-xs-12 col-sm-8 col-sm-offset-2 lastCardAction\">\n            <div class=\"lastCardActionArea\">\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || alias2).call(alias1,"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                <div class=\"actionLoadCard retired-load_all_last_card\">\n                    <h3>Are you ready to address housing inequality through new, innovative solutions? Submit your solution today. <br /></h3>\n                </div>\n                \n            </div>\n        </div>\n    </div>\n</div>\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || alias2).call(alias1,"loggedin",{"name":"checkUser","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});

this["JST"]["navbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"navbarBtnWrap hidden-xs\">\n          <a href=\"#rp\" class=\"navbarBtn btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Return to Dashboard</a>\n        </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"navbarBtnWrap hidden-xs\">\n          <a href=\"#login\" class=\"navbarBtn btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Login</a>\n        </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "          <span class=\"navbarBtnWrap hidden-xs\">\n            <a href=\"#register\" class=\"navbarBtn btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">\n              <span class=\"dayCount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1), depth0))
    + "</span>&nbsp;<span><span class=\"dayCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeFrame : stack1), depth0))
    + "</span> to <strong>Register</strong></span>\n            </a>\n          </span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"navbarBtnWrap hidden-xs\">\n            <a href=\"#login\" class=\"navbarBtn btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Login</a>\n          </span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav class=\"navbar\" role=\"navigation\">\n  <div class=\"container-fluid\">\n\n    <div class=\"navbarHeader layout-vertical-center\">\n      <a class=\"navbarBrand anim\" href=\"/#home\"></a>\n    </div>\n\n    <div class=\"navbarMenuWrap\">\n      <div class=\"navbarMenuBtn jsMenuControl\">\n        <span class=\"menuLabel\">\n            <span class=\"materialIcons\">add</span>\n        </span>\n      </div>\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "      <div class=\"homepageLinks\">\n        <a href=\"#why\" data-scrollto=\"#why\">Why</a>\n        <a href=\"#map\" data-scrollto=\"#map\">Map</a>\n        <a href=\"#process\" data-scrollto=\"#process\">Process</a>\n      </div>\n    </div>\n\n  </div>\n</nav>";
},"useData":true});

this["JST"]["newsDetailsModal"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1),"!=",((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1),{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\n                        <a href=\"#news/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"additionalPost prevPost\">\n\n                            <div class=\"additionalPostContent\">\n                                <div class=\"additionalPostImage\" style=\"background-image: url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.image : stack1), depth0))
    + ")\"></div>\n                                <span class=\"additionalPostContainerTitle\">Previous Post</span>\n                                <h4 class=\"additionalPostTitle\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                                <p class=\"additionalPostSummary\">"
    + alias2((helpers.truncate || (depth0 && depth0.truncate) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.description : stack1),155,{"name":"truncate","hash":{},"data":data}))
    + "</p>\n                                <button class=\"additionalPostBtn hidden-xs\">Find out More</button>\n                            </div>\n                        </a>\n\n                        <a href=\"#news/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"additionalPost nextPost\">\n\n                            <div class=\"additionalPostContent\">\n                                <div class=\"additionalPostImage\" style=\"background-image: url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.image : stack1), depth0))
    + ")\"></div>\n                                <span class=\"additionalPostContainerTitle\">Next Post</span>\n                                <h4 class=\"additionalPostTitle\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                                <p class=\"additionalPostSummary\">"
    + alias2((helpers.truncate || (depth0 && depth0.truncate) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.description : stack1),155,{"name":"truncate","hash":{},"data":data}))
    + "</p>\n                                <button class=\"additionalPostBtn hidden-xs\">Find out More</button>\n                            </div>\n                        </a>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"newsModal\">\n\n    <div class=\"newsModalCard\">\n\n        <div class=\"innerScrollable\">\n\n            <div class=\"newsModalTopCard\">\n                <div class=\"container\">\n                    <div class=\"row\">\n                        <div class=\"col-md-6 newsItemImageContainer\">\n                            <div class=\"newsModalNav\">\n                                <div class=\"newsModalCloseWrap\">\n                                    <div class=\"newsModalCloseBtn modalClose\">Back To News</div>\n                                </div>\n                            </div>\n                            <div class=\"newsModalImage\" style=\"background-image: url('"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.image : stack1), depth0))
    + "');\"></div>\n                        </div>\n                        <div class=\"col-md-6 newsItemTitleContainer\">\n                            <h4 class=\"newsItemTitle hidden-xs\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n\n            <div class=\"newsModalContentContainer\">\n\n                <div class=\"container\">\n                    <div class=\"row\">\n                        <div class=\"col-md-offset-6 col-md-6\">\n                            <div class=\"newsModalContent\">\n                                <div class=\"contentOneContainer\">\n                                    <div class=\"col-md-6 newsItemTitleContainer visible-xs\">\n                                        <h4 class=\"newsItemTitle\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                                    </div>\n                                    <div class=\"content contentOneContainer\">\n                                        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.content : stack1), depth0)) != null ? stack1 : "")
    + "\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n\n                    </div>\n\n                    <div class=\"row\">\n                        <div class=\"col-md-6\">\n                            <div class=\"newsModalContent\">\n                                <div class=\"content contentTwoContainer\">\n                                    "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.contentTwo : stack1), depth0)) != null ? stack1 : "")
    + "\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"col-md-6\">\n                            <span class=\"newsQuote\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.newsQuote : stack1), depth0))
    + "</span>\n                        </div>\n\n                    </div>\n\n                    <div class=\"row\">\n                        <div class=\"col-md-offset-5 col-md-7\">\n                            <div class=\"newsModalContent\">\n                                <div class=\"content contentThreeContainer\">\n                                    "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.contentThree : stack1), depth0)) != null ? stack1 : "")
    + "\n                                </div>\n                            </div>\n                        </div>\n\n                    </div>\n\n\n                </div>\n\n\n            </div>\n\n            <div class=\"additionalPostsContainer\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n\n        </div>\n    </div>\n\n</div>";
},"useData":true});

this["JST"]["news_navigation"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <div class=\"newsItem\" data-id="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + ">\n                    <div class=\"newsItem-image-wrap\" style=\"background-image: url('"
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "');\"></div>\n                    <div class=\"newsItemContentWrap\">\n                        <div class=\"newsItem-top-wrap\">\n                            <div class=\"news-title\">"
    + alias4((helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),22,{"name":"truncate","hash":{},"data":data}))
    + "</div>\n                        </div>\n                        <div class=\"news-content\">"
    + ((stack1 = (helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),300,{"name":"truncate","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n                        <a class=\"news-more\" data-category=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">Find out More</a>\n                    </div>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                        <a class=\"newsItemLink "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-targetid=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                            <span class=\"newsItemLinkText\">_"
    + alias3((helpers.formatNumber || (depth0 && depth0.formatNumber) || alias2).call(alias1,(helpers.plusOne || (depth0 && depth0.plusOne) || alias2).call(alias1,(data && data.index),{"name":"plusOne","hash":{},"data":data}),{"name":"formatNumber","hash":{},"data":data}))
    + "</span>\n                        </a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "first";
},"6":function(container,depth0,helpers,partials,data) {
    return "last";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"newsOverviewContainer wrapper clearfix\">\n    <div class=\"container\">\n        <div class=\"newsItems col-md-offset-1 col-md-11\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.newsItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div id=\"skrollr-body\">\n        <div class=\"navigation\" data-anchor-target=\".newsContainer > .wrapper\" data-top=\"position: fixed; top: 0px;\" data-start=\"position:absolute;top:0px;\" data-end=\"top:-90vh;\" data-bottom=\"top:0px\">\n            <div class=\"navigationLinksWrapper hidden-xs\">\n                <div class=\"navigationLinksInnerWrapper\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.newsItems : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <a class=\"btnPrev\">\n                <span><img src=\"img/arrow-up-black.png\"/></span>\n            </a>\n            <a class=\"btnNext\">\n                <span><img src=\"img/arrow-down-black.png\"/></span>\n            </a>\n        </div>\n    </div>\n</div>\n\n<div class=\"newsDetailContainer\"></div>";
},"useData":true});

this["JST"]["portraitmask"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"portraitmask layout-vertical-center\">\n    <div class=\"portraitmaskBrand\"></div>\n    <div style=\"width:100%\">\n        <div class=\"portraitmaskImage\"></div>\n        <div class=\"portraitmaskContent\">\n            <p>This website was designed to be experienced in <span class=\"visible-landscape-mode\">portrait</span><span class=\"visible-portrait-mode\">landscape</span> mode. We apologize for any inconvenience this may cause!</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["processstepdetail"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "        <a href=\""
    + ((stack1 = ((helper = (helper = helpers.btnLink || (depth0 != null ? depth0.btnLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnLink","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" class=\"processCardDetailButton btn\">"
    + ((stack1 = ((helper = (helper = helpers.btnText || (depth0 != null ? depth0.btnText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"cardContent\" data-processid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"processCardNumberContainer\">\n        <span class=\"processCardNumber layout-vertical-center\">"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "</span>\n    </div>\n    <h3 class=\"processCardDetailTitle\">"
    + ((stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n    <span class=\"processCardDetailDate\">"
    + ((stack1 = ((helper = (helper = helpers.dates || (depth0 != null ? depth0.dates : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dates","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n    <div class=\"processCardDetailContent\">\n      "
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.btnText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["JST"]["processsteps"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"processCard\" data-processid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                <span class=\"processCardTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"item"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                        <div class=\"cardContent\" data-processid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                            <div class=\"processCardDetailTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\n                            <div class=\"processCardDetailContent\">\n                                "
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                            </div>\n\n                            <div class=\"processCardDetailFooter\">\n                                <span class=\"processCardDetailStep\">Step "
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.btnText : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\n\n                            <div class=\"processCardDetailImg\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></div>\n                        </div>\n                    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " active";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "                                    <a href=\""
    + ((stack1 = ((helper = (helper = helpers.btnLink || (depth0 != null ? depth0.btnLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnLink","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" class=\"processCardDetailButton btn\">"
    + ((stack1 = ((helper = (helper = helpers.btnText || (depth0 != null ? depth0.btnText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"processContainer\">\n    <div class=\"processCardsContainer\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"processCardDetailsContainer\">\n\n        <div id=\"processCaro\" class=\"processCardDetailsCarousel carousel slide\" data-ride=\"carousel\">\n            <div class=\"carousel-inner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["resources"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "				\n";
  stack1 = ((helper = (helper = helpers.desktop_width || (depth0 != null ? depth0.desktop_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_width","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "	\n";
  stack1 = ((helper = (helper = helpers.phone_width || (depth0 != null ? depth0.phone_width : depth0)) != null ? helper : alias2),(options={"name":"phone_width","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.phone_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "	\n";
  stack1 = ((helper = (helper = helpers.xs_width || (depth0 != null ? depth0.xs_width : depth0)) != null ? helper : alias2),(options={"name":"xs_width","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.xs_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "	\n	\n	\n"
    + ((stack1 = container.invokePartial(partials.resourcepost,depth0,{"name":"resourcepost","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	\n";
  stack1 = ((helper = (helper = helpers.xs_width || (depth0 != null ? depth0.xs_width : depth0)) != null ? helper : alias2),(options={"name":"xs_width","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.xs_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "	\n";
  stack1 = ((helper = (helper = helpers.phone_width || (depth0 != null ? depth0.phone_width : depth0)) != null ? helper : alias2),(options={"name":"phone_width","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.phone_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "	\n";
  stack1 = ((helper = (helper = helpers.desktop_width || (depth0 != null ? depth0.desktop_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_width","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),3,{"name":"rowOpen","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"row\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),2,{"name":"rowOpen","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),1,{"name":"rowOpen","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),1,{"name":"rowClose","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "		</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),2,{"name":"rowClose","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),3,{"name":"rowClose","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.posts : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["JST"]["scoring"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <div class=\"sliderTick\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"scoreEntry\">\n    <h3 class=\"scoreTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n    <div class=\"scoreHeading\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</div>\n    <div class=\"scoreDescription\">\n        <span class=\"scoreDescriptionInner\">\n            <span class=\"scoreDescriptionValueNumber\"> <span class=\"scoreDescriptionValueNumberInner\"></span> points.</span>\n            <span class=\"scoreDescriptionText\"></span>\n        </span>\n    </div>\n    <div class=\"scoreValue\">\n        <div class=\"scoreValueSpace\">\n            <span class=\"valueNumber\" status=\""
    + alias4(((helper = (helper = helpers.statusScore || (depth0 != null ? depth0.statusScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusScore","hash":{},"data":data}) : helper)))
    + "\"></span>\n            <span class=\"valueBorder valueBorderLeft\"></span>\n            <span class=\"valueBorder valueBorderRight\"></span>\n        </div>\n    </div>\n    <div class=\"scoreSliderWrap\">\n        <div class=\"scoreSlider\">\n            <input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n        </div>\n        <div class=\"sliderTicks\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"sliderAnchor sliderAnchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</div>\n        <div class=\"sliderAnchor sliderAnchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>\n</section>\n";
},"useData":true,"useDepths":true});

this["JST"]["sidebar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebaritem,depth0,{"name":"sidebaritem","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebarInnerWrap\">\n    <span class=\"txt\">Menu</span>\n\n  <div class=\"closeWrap closeButton\">\n    <i class=\"materialIcons\">close</i>\n  </div>\n\n  <div class=\"sidebarItemContainer\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.menuItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n\n  <div class=\"socialShare\">\n    <div class=\"txtWrap\">Share the project</div>\n    <div class=\"linksWrap\">\n        <a target=\"_blank\" class=\"shareLink facebookLink shareSocial\" data-action=\"facebook\" data-title=\"The Reconstruct Challenge, sponsored by Access Ventures, invites you to submit new, innovative solutions to help address housing inequality and alleviate ancillary costs for housing insecure individuals\">\n            <span class=\"socialTxt\">Facebook</span>\n            <span class=\"socialIcon\">\n              <img src=\"img/social_facebook.svg\" alt=\"Facebook Social Icon\">\n            </span>\n        </a>\n        <a target=\"_blank\" class=\"shareLink shareSocial\" data-action=\"twitter\" data-title=\"www.reconstructchallenge.com: seeking new ideas to address housing inequality and alleviate ancillary costs for housing insecure individuals @AcsVentures\">\n            <span class=\"socialTxt\">Twitter</span>\n            <span class=\"socialIcon\">\n              <img src=\"img/social_twitter.svg\" alt=\"Twitter Social Icon\">\n            </span>\n        </a>\n        <a target=\"_blank\" class=\"shareLink  shareSocial\" data-action=\"linkedin\" data-title=\"The Reconstruct Challenge, sponsored by Access Ventures, invites you to submit new, innovative solutions to help address housing inequality and alleviate ancillary costs for housing insecure individuals\">\n            <span class=\"socialTxt\">LinkedIn</span>\n            <span class=\"socialIcon\">\n              <img src=\"img/social_linkedin.svg\" alt=\"Twitter Social Icon\">\n            </span>\n        </a>\n    </div>\n  </div>\n\n\n</div>\n";
},"usePartial":true,"useData":true});

this["JST"]["social"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"txtWrap\">Share <span class=\"materialIcons\">add</span></div>\n<div class=\"linksWrap\">\n    <a href=\"#\" target=\"_blank\" class=\"shareLink facebookLink shareSocial\" data-action=\"facebook\" data-title=\"Facebook Share Text\">\n        <span class=\"socialIcon\">FB</span>\n    </a>\n    <a href=\"#\" target=\"_blank\" class=\"shareLink shareSocial\" data-action=\"twitter\" data-title=\"Twitter Share Text\">\n        <span class=\"socialIcon\">TW</span>\n    </a>\n    <a href=\"#\" target=\"_blank\" class=\"shareLink  shareSocial\" data-action=\"linkedin\" data-title=\"Linkedin Share Text\">\n        <span class=\"socialIcon\">LI</span>\n    </a>\n</div>";
},"useData":true});

this["JST"]["sponsorCarousel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"carousel-item item "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n          <h2 class=\"sponsorName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h2>\n          <p class=\"sponsorText\">"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</p>\n          <button class=\"btnPrimary btnPrimaryAlternate\">Action</button>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"carousel slide\" data-ride=\"carousel\">\n  <div class=\"sponsorWrap carousel-inner\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.sponsor : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"btnNavWrap\">\n    <a class=\"btnNav prev materialIcons\" data-slide=\"prev\" data-target=\".sponsorCarousel .carousel\">keyboard_arrow_left</a>\n    <a class=\"btnNav next materialIcons\" data-slide=\"next\" data-target=\".sponsorCarousel .carousel\">keyboard_arrow_right</a>\n  </div>\n</div>\n";
},"useData":true});

this["JST"]["statscarousel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <article class=\"item statCarouselItem"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <div class=\"statCarouselText\">\n                    "
    + alias4(((helper = (helper = helpers.stat || (depth0 != null ? depth0.stat : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stat","hash":{},"data":data}) : helper)))
    + "\n                    <a class=\"statCarouselMore\" href=\""
    + alias4(((helper = (helper = helpers.moreUrl || (depth0 != null ? depth0.moreUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"moreUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.moreText || (depth0 != null ? depth0.moreText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"moreText","hash":{},"data":data}) : helper)))
    + "</a>\n                </div>\n                <figure class=\"statCarouselImage\">\n                    <img src=\""
    + alias4(((helper = (helper = helpers.imageUrl || (depth0 != null ? depth0.imageUrl : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageUrl","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.source || (depth0 != null ? depth0.source : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"source","hash":{},"data":data}) : helper)))
    + "\">\n                </figure>\n            </article>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <li class=\"promoCarouselDotsLink\" data-target=\"#statCarousel\" data-slide-to=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\""
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " class=\"active\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"statCarousel\" class=\"carousel slide statCarousel\" data-ride=\"carousel\">\n    <div class=\"carousel-inner statCarouselInner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <a class=\"left carousel-control statCarouselArrow\" href=\"#statCarousel\" data-slide=\"prev\"></a>\n    <a class=\"right carousel-control statCarouselArrow\" href=\"#statCarousel\" data-slide=\"next\"></a>\n\n    <ol class=\"carousel-indicators statCarouselDots\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ol>\n</div>\n";
},"useData":true});

this["JST"]["timeline"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"timelinePointWrapper "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-periodname=\""
    + alias4(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" style=\"top:"
    + alias4(((helper = (helper = helpers.offsetPercentage || (depth0 != null ? depth0.offsetPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"offsetPercentage","hash":{},"data":data}) : helper)))
    + "%;\">\n          <div class=\"timelinePoint "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-destination-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n          <div class=\"timelineConnectLine "
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(depth0 != null ? depth0.sizePx : depth0),"<=",0,{"name":"ifComp","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"height: "
    + alias4(((helper = (helper = helpers.sizePx || (depth0 != null ? depth0.sizePx : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizePx","hash":{},"data":data}) : helper)))
    + "px\"></div>\n          <div class=\"timelineBoxWrapper\">\n            <div class=\"timelineBox\">\n              <span class=\"timelineBoxArrow\"></span>\n              <h3 class=\"timelineBoxTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n              <h6 class=\"timelineBoxSubtitle\">"
    + alias4(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</h6>\n              <div class=\"timelineBoxDetails\">\n                "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              </div>\n            </div>\n          </div>\n          \n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "first";
},"4":function(container,depth0,helpers,partials,data) {
    return "last";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"8":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "                  <div class=\"timelineBoxBtnWrap\">\n                    <a class=\"timelineBoxBtn btnPrimary btnPrimaryAlternate\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.btntxt || (depth0 != null ? depth0.btntxt : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btntxt","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n                  </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <div class=\"timelinePeriod\" style=\"top: "
    + alias4(((helper = (helper = helpers.offsetPercentage || (depth0 != null ? depth0.offsetPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"offsetPercentage","hash":{},"data":data}) : helper)))
    + "%; height:"
    + alias4(((helper = (helper = helpers.sizePercentage || (depth0 != null ? depth0.sizePercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizePercentage","hash":{},"data":data}) : helper)))
    + "%\" data-periodname=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"timelinePeriodDetailsWrap container\">\n          <div class=\"timelinePeriodDetails\">\n            <h3 class=\"timelinePeriodName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n            <h5 class=\"timelinePeriodLength\">"
    + alias4(((helper = (helper = helpers.periodText || (depth0 != null ? depth0.periodText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"periodText","hash":{},"data":data}) : helper)))
    + "</h5>\n          </div>\n        </div>\n      </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "          <a class=\"timelineItemLink "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-targetid=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n              <span class=\"timelineItemLinkText\">_"
    + alias3((helpers.formatNumber || (depth0 && depth0.formatNumber) || alias2).call(alias1,(helpers.plusOne || (depth0 && depth0.plusOne) || alias2).call(alias1,(data && data.index),{"name":"plusOne","hash":{},"data":data}),{"name":"formatNumber","hash":{},"data":data}))
    + "</span>\n          </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"timelineContainer\">\n\n  <div class=\"timelineContainerInner\">\n\n    <div class=\"timelinePointsOuterWrapper container\">\n      <div class=\"timelineCurrentMoment\" style=\"top:"
    + container.escapeExpression(((helper = (helper = helpers.currentMommentOffsetPercentage || (depth0 != null ? depth0.currentMommentOffsetPercentage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currentMommentOffsetPercentage","hash":{},"data":data}) : helper)))
    + "%;\"></div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePeriods : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <!--<span class=\"timelineNavigation timelineNavigation--hidden\"-->\n          <!--data-anchor-target=\".timelineContainer\"-->\n          <!--data--180-top=\"position: fixed; top: 45vh;\"-->\n          <!--data-start=\"position:absolute; top: 45vh;\"-->\n          <!--data-end=\"top: -80vh;\"-->\n          <!--data-bottom=\"top: 45vh;\">-->\n  </div>\n  <span class=\"timelineNavigation timelineNavigation\" \n    data-anchor-target=\".timelineContainer\" \n    data-top=\"position: fixed; top: 190px;\" \n    data-start=\"position:absolute; top: 190px;\" \n    data-end=\"top: -90vh;\" \n    data-bottom=\"top: 190px\">\n    <div class=\"navigationLinksWrapper\">\n      <div class=\"navigationLinksInnerWrapper\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </span>\n</div>";
},"useData":true});

this["JST"]["topcardmatrix"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <a href=\"#rp\" class=\"topCardRegister retire-modal-trigger\">Return to Dashboard</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"#login\" class=\"topCardRegister retire-modal-trigger\">Login</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"#register\" class=\"topCardRegister retire-modal-trigger\"><span class=\"daysCount\">64</span>&nbsp;<span class=\"dayCountWord\">Days</span> to register</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"#login\" class=\"topCardRegister retire-modal-trigger\">Login</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});

this["JST"]["404"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"missingPageInner\">\n    <div class=\"container\">\n        <div class=\"row layout-vertical-center\">\n            <div class=\"col-xs-12 col-md-offset-1 col-md-10 text-center\">\n                <div class=\"x_load_404_card1 layout-load-card\">\n                    <h1>Page not found</h1>\n                    <p>Sorry, we couldn’t find the page you requested</p>\n                </div>\n                <a href=\"#home\" class=\"back\">Take Me Back Home</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

if (typeof exports === 'object' && exports) {module.exports = this["JST"];}

return this["JST"];

});
