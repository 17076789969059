define('modules/process/horizontal/js/base.js',['app/module', 'app/config', 'jquery', 'app/util', 'templates', 'moment'], function (module, config, $, util, templates, moment) {
    return new module({
        name: 'process.horizontal',
        selectors: '.processHorizontalStepWrap',
        remoteData: [(config.urls.process)],

        config: {
            cycleProcess: true,
            flipAnimation: true,
            processCarousel: false,
            dateBased: true,
            staticCardContainer: false
        },

        initialize: function (data) {
            this.data = (data || this.data)
            this.renderTemplates(this.data)
            this.addClickHandlers(data)

            if (this.getConfig().dateBased) {
                this.setInitialState()
            }
            else {
                $(this.getSelectors()).find('.processCard[data-processid="1"]').addClass('currentActiveCard')
            }

            if (this.getConfig().processCarousel) {
                // init carousel
                $(this.getSelectors()).find('.carousel.slide').carousel({
                    cycle: false,
                    interval: false
                })
                    .swiperight($.proxy(function () {
                        this.onPrevCard(this.data)
                    }, this))
                    .swipeleft($.proxy(function () {
                        this.onNextCard(this.data)
                    }, this))
            }
        },

        methods: {
            renderTemplates: function (data) {
                $.each(data, function (i, r) {
                    data[i].total = data.length
                });

                $(this.getSelectors()).append(templates.processsteps({
                    steps: data
                }))
            },

            setInitialState: function () {
                var currentDate = new moment(); // Current date, feed moment a date string for testing purposes
                var pastPoints = $.grep(this.data, function (a) {
                    return (currentDate.diff(new Date(a.startDate)) > 0);
                });
                var processId = (pastPoints.length > 0 ? pastPoints[pastPoints.length - 1].id : 1);
                this.showStepContent(processId);
                setTimeout($.proxy(function () {
                    this.setCardPosition(processId);
                    $(this.getSelectors()).find('.processCard[data-processid="1"]').addClass('currentActiveCard');
                }, this), 500);
            },

            addClickHandlers: function (data) {
                var module = this
                $(this.getSelectors()).find('.processCardDetailControls').find('.cardDetailControlNext').click(function (e) {
                    e.preventDefault()
                    module.onNextCard(data)
                })
                $(this.getSelectors()).find('.processCardDetailControls').find('.cardDetailControlPrev').click(function (e) {
                    e.preventDefault()
                    module.onPrevCard(data)
                })

                $(this.getSelectors()).find('.processCard').on('click', function (e) {

                    e.preventDefault()
                    $(this).siblings('.currentActiveCard').removeClass('currentActiveCard')
                    $(this).addClass('currentActiveCard')

                    module.showStepContent($(this).attr('data-processid'))

                    if (module.getConfig().processCarousel) {
                        $('#processCaro.carousel.slide').carousel(parseInt($(this).attr('data-processid')) - 1)
                    }

                })

            },

            onNextCard: function (data) {
                if (this.getConfig().processCarousel) {
                    var currentCardId = $(this.getSelectors()).find('.item.active .cardContent').attr('data-processid'),
                        nextCardEl = $(this.getSelectors()).find('.item .cardContent[data-processid="' + (parseInt(currentCardId) + 1) + '"]')

                    if ($(nextCardEl).length > 0) {
                        $(this.getSelectors()).find('.currentActiveCard').removeClass('currentActiveCard')
                        $(this.getSelectors()).find('.processCard[data-processid="' + (parseInt(currentCardId) + 1) + '"]').trigger('click').addClass('currentActiveCard')
                    }
                    else {
                        if (this.getConfig().cycleProcess) {
                            $(this.getSelectors()).find('.currentActiveCard').removeClass('currentActiveCard')
                            $(this.getSelectors()).find('.processCard[data-processid="' + (parseInt(1)) + '"]').trigger('click').addClass('currentActiveCard')
                        }
                    }

                }
                else {
                    var currentCardId = $(this.getSelectors()).find('.cardContent').attr('data-processid'),
                        nextCardEl = $(this.getSelectors()).find('.processCard[data-processid="' + (parseInt(currentCardId) + 1) + '"]')

                    if ($(nextCardEl).length > 0) {
                        $(nextCardEl).trigger('click')
                        $(this.getSelectors()).find('.currentActiveCard').removeClass('currentActiveCard')
                        nextCardEl.addClass('currentActiveCard')

                    }
                    else {
                        if (this.getConfig().cycleProcess) {
                            $(this.getSelectors()).find('.currentActiveCard').removeClass('currentActiveCard')
                            $(this.getSelectors()).find('.processCard[data-processid="1"]').trigger('click').addClass('currentActiveCard')
                        }
                    }
                }

            },

            // possibly write a config for this, but currently no prev button
            onPrevCard: function (data) {
                if (this.getConfig().processCarousel) {
                    var currentCardId = $(this.getSelectors()).find('.item.active .cardContent').attr('data-processid'),
                        prevCardEl = $(this.getSelectors()).find('.item .cardContent[data-processid="' + (parseInt(currentCardId) - 1) + '"]')

                    if ($(prevCardEl).length > 0) {
                        $(this.getSelectors()).find('.currentActiveCard').removeClass('currentActiveCard')
                        $(this.getSelectors()).find('.processCard[data-processid="' + (parseInt(currentCardId) - 1) + '"]').trigger('click').addClass('currentActiveCard')
                    }
                    else {
                        if (this.getConfig().cycleProcess) {
                            var lastProcessCardID = data[data.length - 1].id
                            $(this.getSelectors()).find('.currentActiveCard').removeClass('currentActiveCard')
                            $(this.getSelectors()).find('.processCard[data-processid="' + lastProcessCardID + '"]').trigger('click').addClass('currentActiveCard')
                        }
                    }

                }
                else {
                    var currentCardId = $(this.getSelectors()).find('.cardContent').attr('data-processid'),
                        prevCardEl = $(this.getSelectors()).find('.processCard[data-processid="' + (parseInt(currentCardId) - 1) + '"]')
                    if ($(prevCardEl).length > 0) {
                        $(prevCardEl).trigger('click')
                        $(this.getSelectors()).find('.currentActiveCard').removeClass('currentActiveCard')
                        prevCardEl.addClass('currentActiveCard')
                    }
                    else {
                        if (this.getConfig().cycleProcess) {
                            $(this.getSelectors()).find('.currentActiveCard').removeClass('currentActiveCard')
                            $(this.getSelectors()).find('.processCard[data-processid="' + (parseInt(data.length)) + '"]').trigger('click').addClass('currentActiveCard')

                        }
                    }
                }
            },

            setCardPosition: function (processId) {
                if (!this.getConfig().staticCardContainer) {
                    if (processId) {
                        var initialOffset = $(this.getSelectors()).find('.processCardDetailsContainer').offset().left - $(this.getSelectors()).find('.processContainer').offset().left,
                            cardWidth = $(this.getSelectors()).find('.processCardsContainer .processCard').outerWidth(true),
                            cardIndex = $(this.getSelectors()).find('.processCard[data-processid="' + processId + '"]').index()
                        var leftOffset = initialOffset - (cardWidth * cardIndex) - (4 * (1 + cardIndex)) // TODO - This might ned refinement for the margin styles
                    } else {
                        var leftOffset = 150
                    }
                    $(this.getSelectors()).find('.processCardsContainer').css('transform', 'translateX(' + leftOffset + 'px)')
                }
            },

            showStepContent: function (processId) {
                var records = $.grep(this.data, function (r) {
                    return r.id == processId
                })
                if (records.length > 0) {
                    if (this.getConfig().flipAnimation) {
                        if ($(this.getSelectors).find('.processCardDetailsContainer .flipper').hasClass('flipping')) {
                            return
                        }
                        var origEl = $(this.getSelectors()).find('.processCardDetailsContainer .processCardDetails')
                        var clonedEl = $(this.getSelectors()).find('.processCardDetailsContainer .processCardDetails').clone()
                        $(clonedEl).insertAfter($(origEl))
                        if ($(this.getSelectors()).find('.processCardDetailsContainer .flipper').length == 0) {
                            $(this.getSelectors()).find('.processCardDetailsContainer .processCardDetails').wrapAll('<div class=\'flipper\' />')
                        }
                        $(clonedEl).addClass('back').html(templates.processstepdetail(records[0]))
                        $(this.getSelectors()).find('.processCardDetailsContainer .flipper').addClass('flipping')
                        setTimeout($.proxy(function () {
                            $(origEl).remove()
                            $(clonedEl).removeClass('back').addClass('front')
                            $(this.getSelectors()).find('.processCardDetailsContainer .flipper').removeClass('flipping')
                        }, this), 750) // This timeout has to be greater than transition/animation speed
                    } else {
                        $(this.getSelectors()).find('.processCardDetailsContainer .processCardDetails').html(templates.processstepdetail(records[0]))
                    }
                    this.setCardPosition(processId)
                    $(this.getSelectors()).find('.processCardDetailControls .current').text(processId)
                }
            }
        }

    })
})
;
