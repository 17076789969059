define('modules/judges/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.livequery"], function (module, config, $, util, templates) {
    return new module({
        name: 'judges.basic',
        selectors: '.judgePageWrap, .judgeSection',
        remoteData: [config.urls.judges],
        routes: [{
            route: 'judges/(.*)',
            fn: function (hash) {
                $('#judges .judgebox[data-judge="' + hash.split('/')[1] + '"]').trigger('click');
            },
            extra: true,
            delay: 2500
        }],


        config: {},

        initialize: function (data) {
            // this.data = (data || this.data);

            this.data = data.sort(function(a, b) {
                if(a.lastName < b.lastName) { return -1; }
                if(a.lastName > b.lastName) { return 1; }
                if(a.firstName < b.firstName) { return -1; }
                if(a.firstName > b.firstName) { return 1; }
                return 0;
            });

            this.renderTemplates(this.data);
            this.initFirst();
            this.groupNav();
        },

        methods: {
            renderTemplates: function (data) {
                var judgeGroups = [],
                    templateData = {
                        judgeGroups: []
                    };
                $.each(data, function (index, record) {
                    if (!judgeGroups[record.type]) {
                        judgeGroups[record.type] = {
                            name: record.type,
                            title: record.shortDescription,
                            desc: record.desc,
                            cms: record.cms,
                            records: []
                        };
                    }
                    judgeGroups[record.type].records.push(record);
                });
                for (var key in judgeGroups) {
                    if (judgeGroups.hasOwnProperty(key)) {
                        templateData.judgeGroups.push(judgeGroups[key]);
                    }
                }
                this.templateData = templateData;
                $('.judgePageWrap').append(templates.judgespage({
                    data: templateData,
                    config: this.config
                }));
            },

            formatNumber: function (n) {
                return n > 9 ? "" + n : "0" + n;
            },

            initFirst: function () {
                var self = this;
                $(this.getSelectors()).find('.judgeGroup').each(function () {
                    $('.judgesDetailContainer', this).find('.judgeInfoContainer').eq(0).addClass('active');
                    $('.judgeGridContainer', this).find('.judgeBox').on('click', $.proxy(self.onJudgeBoxClick, self)).eq(0).addClass('active');
                });
            },

            onJudgeBoxClick: function (e) {
                var self = $(e.delegateTarget);
                $(this.getSelectors()).find('.judgeInfoContainer[data-judge="' + self.attr('data-judge') + '"]').addClass('active').siblings('.active').removeClass('active');
                self.addClass('active').siblings('.active').removeClass('active');
                this.groupNav();
            },

            groupNav: function () {
                var self = this;
                $(this.getSelectors()).find('.judgeInfoGroup').each(function () {
                    var current = $(this),
                        activeIndex = current.find('.judgeInfoContainer.active').index() + 1,
                        lastIndex = current.find('.judgeInfoContainer').length,
                        list = current.find('.judgeInfoContainer'),
                        count = current.find('.judgeDetailCounter .currentCount'),
                        total = current.find('.judgeDetailCounter .totalCount'),
                        nav = current.closest('.judgeGroup').find('.judgeGridContainer .judgeBox');

                    count.html(self.formatNumber(activeIndex));
                    total.html(self.formatNumber(lastIndex));
                    list.filter('.active').find('.judgeInfoScroll').scrollTop(0);

                    $('.judgeDetailNav.next:not(.inited)', current).unbind('click').click(function () {
                        activeIndex = list.filter('.active').index() + 1;
                        if (activeIndex === lastIndex) {
                            list.filter('.active').removeClass('active');
                            list.eq(0).addClass('active');
                            nav.filter('.active').removeClass('active');
                            nav.eq(0).addClass('active');
                            count.html(self.formatNumber(1));
                        } else {
                            list.filter('.active').removeClass('active').next('.judgeInfoContainer').addClass('active');
                            nav.filter('.active').removeClass('active').next().addClass('active');
                            count.html(self.formatNumber(activeIndex + 1));
                        }
                        list.filter('.active').find('.judgeInfoScroll').scrollTop(0);
                        $(this).addClass('inited');
                    });

                    $('.judgeDetailNav.prev:not(.inited)', current).unbind('click').click(function () {
                        activeIndex = current.find('.judgeInfoContainer.active').index() + 1;
                        if (activeIndex === 1) {
                            list.filter('.active').removeClass('active');
                            list.eq(lastIndex - 1).addClass('active');
                            nav.filter('.active').removeClass('active');
                            nav.eq(lastIndex - 1).addClass('active');
                            count.html(self.formatNumber(lastIndex));
                        } else {
                            list.filter('.active').removeClass('active').prev('.judgeInfoContainer').addClass('active');
                            nav.filter('.active').removeClass('active').prev().addClass('active');
                            count.html(self.formatNumber(activeIndex - 1));
                        }
                        list.filter('.active').find('.judgeInfoScroll').scrollTop(0);
                        $(this).addClass('inited');
                    });
                });
            }
        }
    });
});

