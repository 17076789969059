define('modules/scoring/classic/js/base.js',['app/config', 'jquery', 'app/util', 'app/module', 'templates', 'bootstrap/slider'], function (config, $, util, module, templates) {
  return new module({
    name: 'scoring',
    selectors: '.scoreSection',
    remoteData: [(config.urls.scoring)],

    config: {},

    initialize: function (data) {
      this.createQuestions(data);
      this.responsiveBehaviour();
      this.attachEventHandlers();
    },

    methods: {
      createQuestions: function (data) {
        var body = $('body'),
          section = $('.scoreSection');

        $.each(data, $.proxy(function (index, record) {
          section.append($(templates.scoring($.extend(record, {
            index: (index + 1),
            total: data.length
          }))));

          var entry = $('.scoreEntry', section).last(),
            input = $('.scoreSliderInput', entry),
            description = $('.scoreDescriptionText', entry),
            number = $('.scoreDescriptionValueNumberInner', entry),
            borders = $('.scoreValue span', entry);

          input
            .slider()
            .on('slideStart', function () {
              body.trigger('animrefresh', $(this))
            })
            .on('slide slideStop', function (event) {
              var v = event.value || this.value;
              $('.tickBottom').removeClass('highlight');
              $('.tickBottom', entry).eq(Math.floor(v)).addClass('highlight');
              description.text(record.items[Math.floor(v)].text);
              number.text(v);
              borders.css('left', $('.min-slider-handle', entry)[0].style.left);
            })
            .trigger('slide');
        }))
      },

      responsiveBehaviour: function () {
        // Mobile initial states & responsive sizing handling
        $(window).smartresize(function (e) {
          if ($(window).width() >= 768) {
            $('.scoreSection .scoreEntry').show()
          } else {
            //$('.scoreSection section').hide();
            //$('.scoreSection section').first().show();
            //$('.scoreSection').height(window.innerHeight);
            // Tick dividers

            // $('.scoreSection .scoreEntry').each(function () {
            //   var splitValue = $(this).find('.tickTop').eq(1).offset().left - $(this).find('.tickTop').eq(0).offset().left;
            //   $(this).find('.tickTop').each(function () {
            //     $(this).css('margin-left', splitValue / 2);
            //   });
            //   // TODO - THIS ONLY IMPACTS MOBILE
            //   // This was causing issues and couldn't determine why it was necessary
            //   //$(this).find('.tickTop').last().remove();
            // });

            // Scrolling ticks
            $('.scoreSection .scoreEntry').each(function () {
              var tickCount = $(this).find('.tickBottom').length;
              if (tickCount > 4) {
                var totalTickWidth = 70 * tickCount,
                  sliderTicksEl = $(this).find('.sliderTicks');
                $(sliderTicksEl).width('auto').css({
                  'margin-left': '50px',
                  'left': '0px'
                });
                $(sliderTicksEl).find('.tickBottom').each(function (index, tick) {
                  $(tick).css({
                    'min-width': '70px',
                    'left': (70 * index)
                  })
                });
                $(sliderTicksEl).find('.tickTop').each(function (index, tick) {
                  $(tick).css({
                    'left': (70 * index) + 35
                    //'left': (70 * index)
                  })
                });
                // Set slider listener and set initial value position
                $(this).find('.scoreSliderInput').on('slide', function (event) {
                  var totalShift = ((tickCount + 1) * 70) - window.innerWidth;
                  var shiftIncrement = (totalShift * ((event.value) / tickCount));
                  $(sliderTicksEl).css('left', -(shiftIncrement))
                });
                var totalShift = ((tickCount + 1) * 70) - window.innerWidth;
                var shiftIncrement = (totalShift * (($(this).find('.scoreSliderInput').slider('getValue')) / tickCount));
                $(sliderTicksEl).css('left', -(shiftIncrement))
              }
            })
          }
        }).trigger('resize') // Immediately trigger
      },

      attachEventHandlers: function () {
        // General
        $('body').livequery('.scoreEntry:visible', function () {
          setTimeout(function () {
            $('body').trigger('animrefresh', $(this))
          }, 2500)
        })
      }
    }
  })
});
