define('modules/faqs/cards/js/base.js',["app/module","app/config","jquery","app/util","templates","jquery.livequery","app/features/expandergrid"], function(module,config,$,util,templates) {
    return new module({
        name: 'faqs.cards',
        selectors: '.faqWrap',
        remoteData: [(config.urls.faqs)],
        
        config: {
            
        },

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.clickHandlers(this.data);
            this.wrapRows();

        },

    methods: {
      renderTemplates: function (data) {
        $.each(data, function (i, r) {
          data[i].total = data.length;
        });
        $(this.getSelectors()).append(templates.faqcards({
          records: data
        }));

                var module = this;
                $( window ).resize(function() {
                    $(module.getSelectors()).find('.expanderNav .faqNavClose').trigger('click');
                    module.wrapRows();
                });

      },

      wrapRows: function() {
                var w = $( window ).width();
                var divs = $(".qaCard");

                divs.unwrap();

                if (w > 768) {
                    for(var i = 0; i < divs.length; i+=3) {
                        divs.slice(i, i+3).wrapAll('<div class="row"></div>');
                    }
                }

                else {
                    for(var i = 0; i < divs.length; i+=1) {
                        divs.slice(i, i+1).wrapAll('<div class="row"></div>');
                    }
                }
      },

      getActiveColumn: function() {
        return $('.expanderGrid').find('.qaCardInner[data-active="true"]').first();
      },
      
      clickHandlers: function (data) {
        var gridCount = 0;
          $('.expanderGrid').find('.qaCardInner').each(function(e){
        gridCount++;
        });

        $(this.getSelectors()).find('.counter .total').text(gridCount);

        // minus one since zero index
        var gridCountIndex = gridCount - 1;

        // TODO this seemed better suited within the new module, however revisit
        $(this.getSelectors()).find('.qaCardInner').on('click',function(e) {

          var index = $(this).attr('index');
          index = parseInt(index) + 1;

          $('.expanderGrid').find('.counter .index').text(index);

          // Set the active attribute on clicked column
          $('.expanderGrid').find('.qaCardInner').attr('data-active',false);

          // append up arrow to grid border
          // $('.expanderGrid').find('.right-arrow').not($('.expanderGrid').find('.right-arrow.next')).remove();
          $(this).attr('data-active',true);

          // Update and move description row
          var parentRow = $(this).parents('.row').first();
          $('.expanderGrid').find('.detailRow .expanderContent').html($(this).find('.innerSwap').html());
          if($(this).width()*2 > $('.expanderGrid').width()) {
            $('.expanderGrid').find('.detailRow').insertAfter($(this));
          } else {
            $('.expanderGrid').find('.detailRow').insertAfter(parentRow);
          }
          $('.expanderGrid').find('.detailRow').fadeIn({
            complete: $.proxy(function() {
              $(this).trigger('mouseenter'); // Trigger hover for repositioning as DOM moves
            },this)
          });

          // TODO better mobile handling
          // write util
          // if ( $('.pageContainer.active').attr('data-route') != 'home' ) {
          //   if (w < 768) {
          //     $('body').addClass('locked');
          //     $('.navbarWrap').addClass('hidden');
          //   } else {
          //     $('html, body').animate({
          //       scrollTop: $(this).offset().top + 550
          //     }, 500);
          //   }
          // } else {
          //   $('html, body').animate({
          //     scrollTop: $(this).offset().top
          //   }, 500);
          // }
        });
        
        // nav clicks
        $(this.getSelectors()).find('.expanderNav span').on('click', $.proxy(function(e) {
          var activeIndex = $('.expanderGrid').find('.qaCardInner').index($(this.getActiveColumn($(e.currentTarget).parents('.expanderGrid'))));
          if($(e.target).hasClass('faqNavNext')) {
            // if we are at the end of the grid items
            // set index to -1 to land on 0 on click next
            if (activeIndex == gridCountIndex) {
              activeIndex = -1;
            }
            $('.expanderGrid').find('.qaCardInner').eq(activeIndex+1).trigger('click');
          } else if($(e.target).hasClass('faqNavPrev')) {
            $('.expanderGrid').find('.qaCardInner').eq(activeIndex-1).trigger('click');
          }
        },this));
        
        // close click
        $(this.getSelectors()).find('.expanderNav .faqNavClose').on('click', $.proxy(function(e){
          // $('.expanderGrid').find('.right-arrow').not($('.expanderGrid').find('.right-arrow.next')).remove();
          $('.detailRow').fadeOut(300);
          $('.detailRow .video-id iframe').attr('src','');
          $('.expanderGrid').find('.qaCardInner').removeAttr('data-active');
          $('body').removeClass('locked');
          $('.navbarWrap').removeClass('hidden');
        },this));

        setTimeout($.proxy(function() {
          $(this.getSelectors()).find('.qaCardInner').first().trigger('click');
        }, this), 100);

        if ($(window).width() < 768) {
          $(this.getSelectors()).find('.faqLeft.stopOver').removeClass('stopOver');
        };
      }
    }
  });
});

