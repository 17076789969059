define('modules/finalists/gridoverlay/js/base.js',[
  'app/config',
  'jquery',
  'app/util',
  'templates',
  'skrollr',
  'waypoints',
  'app/features/video'
], function(config, $, util, templates, skrollr) {
  return {
    name: 'finalists',
    selectors: '.finalistContainer, .granteeContainer',
    remoteData: [config.urls.finalists],

    routes: [
      {
        route: 'finalists',
        fn: function(hash) {
          this.closeAllOverlays(hash);
        },
        extra: true,
        delay: 500
      }, {
        route: 'finalists/(.*)',
        fn: function(hash) {
          this.goToHash2(hash);
        },
        extra: true,
        delay: 2000
      }, {
        route: 'grantees',
        fn: function(hash) {
          this.closeAllOverlays(hash);
        },
        extra: true,
        delay: 500
      }, {
        route: 'grantees/(.*)',
        fn: function(hash) {
          this.goToHash2(hash);
        },
        extra: true,
        delay: 2000
      }, {
        route: '.*',
        fn: function() {
          this.resetGranteesVideo();
        },
        extra: true,
        delay: 250
      }
    ],
    initialize: function(data) {
      var initHtml;

      this.data = (data || this.data);
      this.renderTemplates(this.data);
      //this.finalistOrginalData(this.data);
      //this.finalistTabHandler(this.data);
      //this.refreshFinalistSlider(data);
      this.finalistOverlays(data);

    },

    closeAllOverlays: function(hash) {

      if ($('.finalist-overlay-grid').hasClass('open')) {
        $('.finalistContainer .close-overlay')[0].click();
        $('.granteeContainer .close-overlay')[0].click();
      }

    },

    goToHash2: function(hash) {
      var scrollToTeam = hash.split('/')[1];
      //console.log(scrollToTeam);

      $(".intro-section-btn[data-scrollto='.section.finalists']").trigger('click');

      var finalistItem = $('.finalist-post[data-teamid="' + scrollToTeam + '"]');
      if (finalistItem) {

        var innerCol = finalistItem.find('.inner-col');
        $(innerCol).trigger('tap');

      }
    },

    goToHash: function(hash) {
      var scrollToTeam = hash.split('/')[1];

      if (scrollToTeam <= 5) {

        $('.finalist-page .intro-section.main').hide();
        $('.finalist-overview').hide();
        $('.participant-overlay-grid').hide();

        $('html,body').scrollTop(0);

        $('.finalist-overlay-grid').fadeIn(500).addClass('open');
        $('.intro-section-container').fadeIn(500);
        $('.intro-section-container .finalist').fadeIn(500);

        setTimeout(function() {
          $('html, body').animate({
            scrollTop: $('.finalist-container[data-finalist="' + scrollToTeam + '"]').offset().top - 50
          }, 1000);
          $('body').trigger('pagenavupdate');
        }, 2000);

        $('body').trigger('pagenavupdate');
        $('.finalist-page .sideNavWrap').fadeIn();
        $('.finalist-page .closeNav').fadeIn();

      } else {

        $('.finalist-page .intro-section.main').hide();
        $('.finalist-overview').hide();
        $('.finalist-overlay-grid').hide();

        $('html,body').scrollTop(0);

        $('.participant-overlay-grid').fadeIn(500).addClass('open');
        $('.intro-section-container').fadeIn(500);
        $('.intro-section-container .participant').fadeIn(500);

        setTimeout(function() {
          $('body').trigger('pagenavupdate');
          $('html, body').animate({
            scrollTop: $('.participant-container[data-id="' + scrollToTeam + '"]').offset().top - 50
          }, 1000);
        }, 2000);

        $('body').trigger('pagenavupdate');
        $('.finalist-page .sideNavWrap').fadeIn();
        $('.finalist-page .closeNav').fadeIn();

      }
    },

    resetGranteesVideo: function() {
      $('.videoSection-container').empty();
      $('.videoSection-container').html('<div class="videoSection-video" data-youtube="NKVapXqOILE" data-autoplay="0" data-poster="img/grantees-video.jpg"></div>');
    },

    renderTemplates: function(data) {

      data.sort(function(a, b) {
        return a.id - b.id;
      });

      $.each(data, function(i, r) {
        data[i].total = data.length;
      });

      var wins = {}, fins = {};
      for (var w in data) {
          if (data[w].winner == true){
            wins[w] = data[w];
          } else {
            fins[w] = data[w];
          }
      }

      $('.winnerContainer').append(templates.finalists({steps: wins}));
      $('.finalistContainer').append(templates.finalists({steps: fins}));

      $('.granteeContainer').append(templates.grantees({steps: data}));

    },

    finalistOverlays: function(data) {

      var module = this;

      $('body').trigger('pagenavupdate');

      $(document).on('click', '.close-overlay', function() {

        $('.navBarWrap').removeClass('slideDownInModal slideUpOutModal');

        var lastActiveFinalist = $('.finalist-container.active').attr('data-finalist');

        //stop any videos
        $('.finalist-container .finalist-video .video-iframe-container').each(function() {
          $(this).html('');
        });

        if ($('.finalist-overlay-grid').hasClass('open')) {
          $('body').trigger('pagenavupdate').removeClass('locked finalistsModalOpen');
          $('.homeFinalistAnnouncement').removeClass('homeFinalistAnnouncement--modal-open');

          $('html,body').animate({
              scrollTop: $('.finalist-post[data-id="'+lastActiveFinalist+'"]').offset().top
          }, 0);

          $('.finalist-overlay-grid').fadeOut().removeClass('open');
          $('.finalist-container').removeClass('active');

        }

      });

      $(document).on('click', '.next-prev-finalists', function(e) {
        e.preventDefault();
        var nextPrevItem = $(this).parents('.finalist-col').attr('data-id');
        $('.finalist-overlay-grid').scrollTop(0);
        $('.finalist-col[data-id="' + nextPrevItem + '"] .go-to-finalists').trigger('click');
      });

      $(document).on('click', '.go-to-finalists', function(e) {
        e.preventDefault();

        module.resetGranteesVideo();

        if (!$('.homeFinalistAnnouncement').hasClass('.homeFinalistAnnouncement--collapse')) {
          $('.homeFinalistAnnouncement').addClass('homeFinalistAnnouncement--modal-open');
        }
        //$('.navbarWrap').addClass('hidden').trigger('pagenavupdate');

        var parId = $(this).parents('.finalist-col').attr('data-id');
        var parIndex = parId - 1;
        var nextItem;
        var prevItem;

        if (parIndex == 0) {
          prevItem = data.length - 1;
          nextItem = parIndex + 1;
        } else if (parIndex == data.length - 1) {
          nextItem = 0;
          prevItem = parIndex - 1;
        } else {
          nextItem = parIndex + 1;
          prevItem = parIndex - 1;
        }

        console.log(parId, parIndex, nextItem, prevItem);

        data[prevItem].index = prevItem;
        data[nextItem].index = nextItem;

        $('.finalist-overlay-grid').html(templates.finalistbio({item: data[parIndex], prevItem: data[prevItem], nextItem: data[nextItem]}));

        $('.pageContainer.active .finalist-overlay-grid').fadeIn(500).addClass('open');
        $('.pageContainer.active .finalist-container[data-finalist="' + parIndex + '"]').addClass('foobar');
        $('.pageContainer.active .finalist-overlay-grid .sideNav .currentCount').html(parId);

        $('.sideNav .currentCount').html(parId);
        $('.finalist-page .sideNavWrap').fadeIn();
        $('.finalist-page .closeNav').fadeIn();

        $('html, body').scrollTop(0);
        $('body').addClass('locked finalistsModalOpen');

        module.handleModalOpenNav();

      });

    },

    handleModalOpenNav: function() {

      $('.finalist-overlay-grid.open').on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(50, util.minScrollSpeed($.proxy(function(event) {

        var st = $('.finalist-overlay-grid.open').scrollTop();
        var stHeight = $('.finalist-overlay-grid.open').height();

        // at the bottom
        if (st == stHeight) {
          // at the bottom
        } else {
          if (st > this.lastScrollTop && st > 0) {

            $('.navbarWrap').removeClass('slideDownInModal').addClass('slideUpOutModal');
          } else {

            if (st < 300) {
              $('.navbarWrap').removeClass('slideUpOutModal').addClass('slideDownInModal');
            }

          }
        }

        this.lastScrollTop = st;

      }, this))));

    }
  }
});

