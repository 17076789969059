define('modules/navbar/compact/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "waypoints", "jquery.livequery"], function (module, config, $, util, templates) {
    return new module({
        name: 'navbar',
        selectors: '.navbarWrap',
        remoteData: [],

        routes: [{
            route: '.*',
            fn: function (hash) {
                // TODO - We need to sweep all the navbar classes and standarize this
                $('.navbar').removeClass('shrink');
                $(this.getSelectors()).removeClass('hidden');

                this.updateNavbarThemeClass();
            },
            extra: true
        }],

        config: {
            hiddenNavBar: true,
            stickySideNavBar: false,
            animatedLogo: true,
            logodelay: false,
            socialExpand: false,
            showOnHover: false,
            headerFooterNavBar: false,
            stickyShrinkHeader: false
        },

        initialize: function () {
            this.$container = $(this.getSelectors());
            this.blackPageHashes = ["", "home", "404"];

            // Apply config
            if ($(this.getSelectors()).attr('data-hiddennav')) {
                this.getConfig().hiddenNavBar = true;
            }

            // Render the template
            $(this.getSelectors()).html(templates.navbar({
                showRegisterLogin: (typeof config.showRegisterLogin == 'undefined' ? true : config.showRegisterLogin),
                config: config // TODO - Filter this down to selected keys
            }));

            // Handling for activePageTitle text swaps
            // TODO - This will not longer properly fire against the :visible selector due to LQ upgrades
            // This is no longer utilized, when we need it again we should roll it into the module routes
            /*$(this.getSelectors()).livequery('.activePageTitle:visible', function () {
                $(this).text(util.getActivePageTitle());
            });*/

            // Set navbar animations depending on layout type / animation mode

            // nav social
            if (this.getConfig().socialExpand) {
                this.setSocialExpandHandlers();
            }

            if (this.getConfig().hiddenNavBar) {
                this.handleHiddenNavBar();
            } else if (this.getConfig().stickySideNavBar) {
                this.handleStickySideNavBar();
            } else if (this.getConfig().headerFooterNavBar) {
                this.handleHeaderFooterNavBar();
            }
            else if (this.getConfig().stickyShrinkHeader) {
                this.handleStickyShrinkHeader();
            }

            else {
                // Automatic detection of animation mode for navbar
                if ($(window).width() < 768) {
                    setTimeout($.proxy(this.basicAnimation, this), 1000);
                } else {
                    this.cardPanel();
                }
            }

            if (this.getConfig().logodelay) {
                var delayTime = (this.getConfig().logodelay);
                setTimeout($.proxy(function () {
                    $('.navbar-brand-holder').addClass('navbarBrand');
                }), delayTime);
            }

            if (this.getConfig().showOnHover) {
                $('body').on('mousemove', $.proxy(function (e) {
                    e.preventDefault();
                    var scrollTop = $(window).scrollTop();
                    if (e.clientY < 200 && scrollTop > 800) {
                        $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn mobile-transition');
                        if (!this.getConfig().animatedLogo) {
                            $('.navbarBrand').removeClass('nb-slidedown').addClass('nb-slideup');
                        }
                    }
                }, this));
            }

            // Global body listeners
            $('body').on('navbartoggle', $.proxy(function (event, state) {
                if (state == true) {
                    $('.navbarWrap').removeClass('slideUpOut').find('.logo-animate-closed').removeClass('logo-animate-closed');
                } else {
                    $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut').find('.navbarBrand').addClass('logo-animate-closed');
                }
                if (this.getConfig().stickySideNavBar) {
                    $('.navbarMenuWrap').toggleClass('stickySideNavBar', !state);
                }
            }, this));

            this.attachEventHandlers();
        },

        methods: {

            attachEventHandlers: function () {
                var $body = $('body'),
                    shareToggle;

                // Detect and handle close button
                this.$container.on('click', '.jsMenuControl', $.proxy(function (e) {
                    e.preventDefault();

                    if ($body.hasClass('menuOpened')) {
                        shareToggle = false;
                    } else {
                        shareToggle = true;
                    };

                    // $('.socialShare').toggleClass('socialExpanded', shareToggle);
                    $body.toggleClass('menuOpened');

                    if ($(window).width() < 768) {
                        $body.toggleClass('locked');
                    };
                }, this));

                this.socialEventHandler();
            },

            socialEventHandler: function() {
                // $('.socialShare .txtWrap').on('click', function() {
                //     var $self = $(this),
                //         parent = $self.parent('.socialShare');

                //     parent.addClass('socialExpanded');
                // })
            },


            // TODO - Figure out not to constrain this context to pageContainer, as that relies on using a specific nav layout
            basicAnimation: function () {
                $('.pageContainer .section:first-of-type').waypoint(function (direction) {
                    $('.navbar').toggleClass('shrink', (direction == 'down'));
                }, {
                    offset: -100,
                    context: '.pageContainer'
                });
                $('.pageContainer .section:last-of-type').waypoint(function (direction) {
                    $('.navbar').toggleClass('shrink', (direction == 'up'));
                }, {
                    offset: 100,
                    context: '.pageContainer'
                });
            },
            cardPanel: function () {
                // Navbar shrink state on panel snap events
                $('body').on('cardsnapfinish', function (event, panel) {
                    if ($(panel).parents('.pageContainer').hasClass('enableNavAni') || util.device.mobile()) {
                        $('.navbar')[($(panel).index() == 0 || $(panel).index() + 1 == $(panel).parent().children().length) ? 'removeClass' : 'addClass']('shrink');
                    }
                });
            },
            handleStickySideNavBar: function () {
                // TODO - Next time we use this method, enable throttling as we did below
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', util.minScrollSpeed($.proxy(function (event) {

                    var scrollTop = $(window).scrollTop();

                    //scroll down
                    if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                        $('.dashboard-menu-social-share').removeClass('social-expanded-on');

                        if (scrollTop > 500) {
                            $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                            setTimeout($.proxy(function () {
                                $('.navbarWrap').addClass('topZIndex')
                            }), 1000);
                            $('.navbarMenuWrap').removeClass('slide-from-right').addClass('stickySideNavBar');
                        }
                    }

                    //scroll up
                    else {
                        if (scrollTop > 500) {
                            $('.navbarMenuWrap').addClass('slide-from-right');
                        }
                        else {
                            $('.navbarMenuWrap').removeClass('slide-from-right');
                            setTimeout($.proxy(function () {
                                $('.navbarMenuWrap').removeClass('stickySideNavBar');
                                $('.navbarWrap').removeClass('slideDownIn slideUpOut topZIndex');
                            }), 10);
                        }
                    }

                    //at top
                    if (scrollTop <= 1) {
                        $('.navbarMenuWrap').removeClass('stickySideNavBar slide-from-right');
                        $('.navbarWrap').removeClass('slideDownIn slideUpOut topZIndex');
                    }

                    this.lastScrollTop = scrollTop;

                }, this)));
            },
            handleHiddenNavBar: function () {
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(175, util.minScrollSpeed($.proxy(function (event) {
                    // TODO test this, specific to navMap
                    if ($('.navbarWrap').hasClass('closed')) {
                    }
                    else {

                        var scrollTop = $(window).scrollTop();
                        if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                            $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                            if (this.getConfig().animatedLogo) {
                                if (!$('.navbarBrand').hasClass('logo-animate-closed')) {
                                    $('.navbarBrand').addClass('logo-animate-closed');
                                }
                            }
                            else {
                                $('.navbarBrand').removeClass('nb-slidedown').addClass('nb-slideup')
                            }
                        }
                        else {
                            if (!this.getConfig().showOnHover) {
                                $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn mobile-transition');
                                if (!this.getConfig().animatedLogo) {
                                    $('.navbarBrand').removeClass('nb-slidedown').addClass('nb-slideup');
                                }
                            }
                            if (scrollTop <= 100) {
                                if (this.getConfig().animatedLogo) {
                                    $('.navbarBrand').removeClass('logo-animate-closed');
                                } else {
                                    $('.navbarBrand').removeClass('nb-slideup').addClass('nb-slidedown');
                                }
                                $('.navbarWrap').removeClass('slideDownIn slideUpOut topZIndex');
                                $('.navbarWrap').removeClass('mobile-transition');
                            }
                        }

                        this.lastScrollTop = scrollTop;
                    }
                }, this))));

            },
            handleHeaderFooterNavBar: function () {
                var $navbarWrap = $(this.getSelectors());

                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.proxy(function (event) {
                    // TODO test this, specific to navMap
                    if ($('.navbarWrap').hasClass('closed')) {
                    }
                    else {
                        var scrollTop = $(window).scrollTop();
                        var windowHeight = $(window).height();
                        var docHeight = $(document).find('body').height();

                        if (!$('body').hasClass('newsModalOpen')) {
                            this.updateNavbarThemeClass(scrollTop);
                            if ((scrollTop + windowHeight) > docHeight - 101) {
                                //bottom part of page
                                $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn mobile-transition');
                            } else {
                                if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                                    // scroll down
                                    $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                                    if (this.getConfig().animatedLogo) {
                                        if (!$('.navbarBrand').hasClass('logo-animate-closed')) {
                                            $('.navbarBrand').addClass('logo-animate-closed');
                                        }
                                    }

                                } else {
                                    // scroll up
                                    $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                                    if (scrollTop <= 300) {
                                        $('.navbarWrap').removeClass('slideUpOut mobile-transition').addClass('slideDownIn');
                                        if (this.getConfig().animatedLogo) {
                                            $('.navbarBrand').removeClass('logo-animate-closed');
                                        }
                                    }
                                }
                            }
                        }


                        this.lastScrollTop = scrollTop;
                    }
                }, this));

                // TODO - This needs to be looked at more carefully and optimized
                // Fallback method
                setInterval($.proxy(function () {
                    if ($(window).scrollTop() <= 300 && !$('body').hasClass('newsModalOpen')) {
                        $('.navbarWrap').removeClass('slideUpOut mobile-transition').addClass('slideDownIn');
                        if (this.getConfig().animatedLogo) {
                            $('.navbarBrand').removeClass('logo-animate-closed');
                        }
                    }
                }, this), 5000);

            },

            handleStickyShrinkHeader: function () {
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(175, util.minScrollSpeed($.proxy(function (event) {
                    // TODO test this, specific to navMap
                    var scrollTop = $(window).scrollTop();
                    var windowHeight = $(window).height();
                    var docHeight = $(document).height();

                    // simplest form
                    if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                        // scroll down
                        $('.navbarWrap').addClass('nav-shrink');

                    }
                    else {
                        // scroll up
                        if (scrollTop <= 300) {
                            $('.navbarWrap').removeClass('nav-shrink');
                        }
                    }
                    this.lastScrollTop = scrollTop;

                }, this))));

            },

            setSocialExpandHandlers: function () {
                // TODO - Convert this size check into a window resize listener
                if ($(window).width() < 992) {
                    // TODO moving forward use this class social-expand-hover-icon
                    $('.dashboard-menu-social-share, .dashboard-menu-social-share .animated-heart-icon, .social-expand-hover-icon').click(function (e) {
                        if (e.target !== this)
                            return;
                        $('.dashboard-menu-social-share').addClass('social-expanded-on');
                    });
                    $('.mobile-close-social').click(function () {


                        $('.dashboard-menu-social-share').removeClass('social-expanded-on');
                    });
                }
                else {
                    $('.dashboard-menu-social-share, .social-expand-hover-icon').hover(
                        function () {
                            $(this).addClass('social-expanded-on');
                        }, function () {
                            $(this).removeClass('social-expanded-on');
                        }
                    );
                }
            },

            updateNavbarThemeClass: function (scrollTop) {
                if (scrollTop == undefined) {
                    scrollTop = $(window).scrollTop();
                }

                var navbarThemeClass = "white";
                var hash = window.location.hash.substring(1);

                if (scrollTop < 400) {
                    $.each(this.blackPageHashes, function (index, pageHash) {
                        if (pageHash == hash) {
                            navbarThemeClass = "dark";
                            return false;
                        }
                    });
                }

                this.$container
                    .removeClass("dark white missing-page-nav")
                    .addClass(navbarThemeClass);
            }
        }
    });
});
