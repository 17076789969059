define('modules/resources/basic/js/base.js',["app/config","jquery","templates","skrollr","waypoints"], function(config,$,templates,skrollr) {
    return {
        name: 'resources',
        selectors: '.resourcesWrap',
        remoteData: [config.urls.resources],
        initialize: function(data) {
        
		$(this.selectors).append(templates.resources({
			posts: data
		}));

        }
    }
});
