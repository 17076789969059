define('modules/sponsorCarousel/basic/js/base.js',["app/module","app/config","jquery","app/util","templates","bootstrap/carousel"], function(module,config,$,util,templates) {
    return new module({
        name: 'sponsorCarousel.basic',
        selectors: '.sponsorCarousel',
        remoteData: [config.urls.sponsors],

        initialize: function (data) {
            this.$container = $(this.getSelectors());
            this.data = (data || this.data);

            this.$container.append(templates.sponsorCarousel({
                sponsor: data
            }));

            this.$container.find('.carousel.slide')
            .carousel({
                interval: 7500,
                pause: null
            });
        },

        methods: {
        }
    });
});

