// require all needed dependencies here
define('modules/news/navigation/js/base.js',["app/config", "jquery", "app/module", "app/util", "templates", "moment", "skrollr"], function (config, $, module, util, templates, moment, skrollr) {
    return new module({
        name: 'news',
        selectors: ".newsContainer",
        remoteData: [config.urls.news],

        routes: [
            {
                route: '.*',
                fn: function (hash) {
                    if (hash.indexOf('news') === -1 && $('body').hasClass('newsModalOpen')) {
                        this.closeDetails();
                    }
                },
                extra: true,
                delay: 10
            },
            {
                route: 'news/(\\d+)',
                fn: function (hash) {
                    var newsId = hash.split('/')[1];
                    this.renderNewsDetails(newsId);
                },
                extra: true,
                delay: 800
            }
        ],

        config: {
            // sets the count of visible links in the navigation
            visibleLinksCount: 5,
            modalHideNav: true
        },

        // once the remote data is fetched this method will be called
        initialize: function (data) {
            // the selector will be always present in the DOM, please use the cached version whenever possible
            this.$container = $(this.getSelectors());
            this.data = data;
            this.renderTemplates(data);
            this.cacheStaticSelectors();
            this.attachEventHandlers();
            this.setInitialState();
        },

        // once the executon of initialize has ended this method will be called
        onRender: function () {
            $('body').trigger('resetskrollr');
        },

        methods: {
            renderTemplates: function (data) {
                // modify the data if needed and prepare a view model forrendering the view
                $.each(data, function (index, item) {
                    item.date = new moment(item.date);
                })
                var viewModel = {
                    newsItems: data
                };

                // render the template and append it to the container
                // most of the time we are refering to a Handlebars template existing in the module itself
                // the template can be overriden if a file with the same name exists in the project specific folder
                this.$container.append(templates.news_navigation(viewModel));
            },

            attachEventHandlers: function (data) {
                var navigationLinksInnerWrapper = $(this.$container.find('.navigationLinksInnerWrapper')),
                    targetDistanceFromTopOfParent,
                    scrollParam = true;

                this.$container.on("click", ".navigation .newsItemLink", $.proxy(function (event) {
                    var $target = $(event.target);

                    //selecting the actual link element if an element inside it is the target
                    if (!$target.hasClass('newsItemLink')) {
                        $target = $target.closest('.newsItemLink');
                    }
                    ;

                    var newsId = $target.data("targetid");
                    var $targetNewsItem = $(this.$container.find(".newsItems .newsItem[data-id='" + newsId + "']"));
                    this.$container.find(".newsItemLink").removeClass("visible selected");

                    // set visibility to the navigation links
                    var visibleLinksCount = this.getConfig().visibleLinksCount;
                    $target.addClass("selected visible");
                    var visibleNewsLinks = $target.nextAll(".newsItemLink:lt(" + (visibleLinksCount - 1) + ")");
                    if (visibleNewsLinks.length < visibleLinksCount - 1) {
                        // if less than required count of links are visible show the very last ones
                        visibleNewsLinks = this.$container.find(".navigation .newsItemLink:gt(-" + (visibleLinksCount + 1) + ")");

                        //this handles the navigation scrolling when you click on the last links

                        targetDistanceFromTopOfParent = visibleNewsLinks.first().position().top;
                    } else {
                        //controlling scrolling of the navigation
                        targetDistanceFromTopOfParent = $target.position().top;
                    }

                    navigationLinksInnerWrapper.css('top', '-' + targetDistanceFromTopOfParent + 'px');

                    visibleNewsLinks.addClass("visible");

                    // show/hide prev/next navigation arrows
                    // this.$btnPrev.show();
                    // this.$btnNext.show();
                    this.$btnPrev.addClass('show-btn');
                    this.$btnNext.addClass('show-btn');
                    if ($target.hasClass("first")) {
                        //this.$btnPrev.hide();
                        this.$btnPrev.removeClass('show-btn');
                    }
                    if ($target.hasClass("last")) {
                        //this.$btnNext.hide();
                        this.$btnNext.removeClass('show-btn');
                    }
                    // scroll to selected news item

                    if (scrollParam === true) {

                        $("html, html body").stop().animate({
                            scrollTop: $targetNewsItem.offset().top
                        }, 700);
                    }
                    ;

                    scrollParam = true;

                    this.$container.find('.currentNewsItem').removeClass('currentNewsItem');
                    $targetNewsItem.addClass('currentNewsItem');

                }, this));

                this.$btnPrev.on("click", $.proxy(function (event) {
                    this.$container.find(".navigation .newsItemLink.selected").prev().trigger("click")
                }, this));

                this.$btnNext.on("click", $.proxy(function (event) {
                    this.$container.find(".navigation .newsItemLink.selected").next().trigger("click")
                }, this));

                //updating navigation on scroll

                var bodyScrolledHeight,
                    currentNewsItem,
                    currentNewsItemOffsetTop,
                    currentNewsItemHeight,
                    newNewsItemId,
                    newNewsItemLink;

                $(window).on('scroll', $.proxy(function () {

                    if (this.$container.is(':visible')) {

                        if (!$('body').is(':animated')) {

                            bodyScrolledHeight = $(document).scrollTop();

                            newNewsItemId = 0;

                            currentNewsItem = $(this.$container.find('.currentNewsItem'));
                            currentNewsItemOffsetTop = currentNewsItem.offset().top;
                            currentNewsItemHeight = currentNewsItem.outerHeight(true);

                            if (bodyScrolledHeight < currentNewsItemOffsetTop - 30) {

                                if (currentNewsItem.prev('.newsItem').length > 0) {
                                    newNewsItemId = currentNewsItem.prev('.newsItem').attr('data-id');
                                }
                                ;
                            } else if (bodyScrolledHeight > currentNewsItemOffsetTop + currentNewsItemHeight + 30) {

                                if (currentNewsItem.next('.newsItem').length > 0) {
                                    newNewsItemId = currentNewsItem.next('.newsItem').attr('data-id');
                                }
                                ;
                            }
                            ;

                            if (newNewsItemId != 0) {
                                newNewsItemLink = $(this.$container.find('.newsItemLink[data-targetid="' + newNewsItemId + '"]'));

                                if (!newNewsItemLink.hasClass('selected')) {
                                    scrollParam = false;
                                    newNewsItemLink.trigger('click');
                                }
                                ;
                            }
                            ;
                        }
                        ;
                    }
                    ;

                    var newsNavigation = $('.newsContainer').find('.navigation');

                    if ($(window).width() < 768) {
                        //you are on mobile resolutions

                        var newsContainerOffsetTop = this.$container.offset().top,
                            newsContainerHeight = this.$container.outerHeight(),
                            windowScrollTop = $(window).scrollTop();

                        if (windowScrollTop < newsContainerOffsetTop) {
                            //you are above the news container element

                            newsNavigation.removeClass('fixed-mobile abs-bottom-mobile').css('position', 'absolute').css('top', '50px');

                        } else if ((windowScrollTop > newsContainerOffsetTop) && (windowScrollTop < (newsContainerOffsetTop + newsContainerHeight - 310))) {
                            //you have reached the news container element and are scrolling in it

                            newsNavigation.removeClass('abs-bottom-mobile').addClass('fixed-mobile').css('position', '').css('top', '');

                        } else if (windowScrollTop > (newsContainerOffsetTop + newsContainerHeight - 310)) {
                            //you have scrolled past the news container element

                            newsNavigation.removeClass('fixed-mobile').addClass('abs-bottom-mobile');
                        }
                        ;
                    } else {
                        //you are on desktop resolutions
                        newsNavigation.removeClass('fixed-mobile abs-bottom-mobile');
                    }
                    ;

                    /* on tablet devices news navigation needs to be explicitely set the fixed
                    position because the sckrollr library is not initialied as usual*/

                    if ($(window).width() >= 768 && $('html').hasClass('touch')) {
                        //you are on tablet resolutions

                        var newsContainerOffsetTop = this.$container.offset().top,
                            newsContainerHeight = this.$container.outerHeight(),
                            windowScrollTop = $(window).scrollTop();

                        if (windowScrollTop < newsContainerOffsetTop) {
                            //you are above the news container element

                            newsNavigation.removeClass('fixed-tablet abs-bottom-tablet').css('position', 'absolute').css('top', '50px');

                        } else if ((windowScrollTop > newsContainerOffsetTop) && (windowScrollTop < (newsContainerOffsetTop + newsContainerHeight - 600))) {
                            //you have reached the news container element and are scrolling in it

                            newsNavigation.removeClass('abs-bottom-tablet').addClass('fixed-tablet').css('position', '').css('top', '');

                        } else if (windowScrollTop > (newsContainerOffsetTop + newsContainerHeight - 600)) {
                            //you have scrolled past the news container element

                            newsNavigation.removeClass('fixed-tablet').addClass('abs-bottom-tablet');
                        }
                        ;
                    } else {
                        //you are on desktop resolutions
                        newsNavigation.removeClass('fixed-tablet abs-bottom-tablet');
                    }
                    ;


                }, this));

                this.$container.on("click", ".newsDetailContainer .modalClose", $.proxy(function (e) {
                    e.preventDefault();
                    window.history.pushState('news', 'News', '#news');
                    this.closeDetails();
                }, this));

                this.$container.find('.news-more').on('click', function () {
                    $('.newsOverviewContainer').addClass('fade-out');
                });
            },

            setInitialState: function (data) {
                this.$container.find(".newsItemLink").first().trigger("click");
            },

            cacheStaticSelectors: function () {
                this.$btnPrev = $(this.$container.find(".navigation .btnPrev"));
                this.$btnNext = $(this.$container.find(".navigation .btnNext"));
            },

            renderNewsDetails: function (newsId) {

                if (!this.isReady()) {
                    this.defferedRenderNewsDetail = newsId;
                    return;
                }

                if (!this.newsById) {
                    var newsById = {};
                    $.each(this.data, $.proxy(function (index, item) {
                        item.originalIndex = index;
                        newsById[item.id] = item;
                    }, this));
                    this.newsById = newsById;
                }

                var newsItem = this.newsById[newsId];
                // TODO: think about the possibility to have 1 or 2 news items of the same category
                if (newsItem) {
                    var i = 0, currentItem;
                    if (!newsItem.prevItem) {
                        for (i = newsItem.originalIndex - 1; i >= 0; i--) {
                            currentItem = this.data[i];
                            if (newsItem.category == currentItem.category) {
                                newsItem.prevItem = currentItem;
                                break;
                            }
                        }
                        if (!newsItem.prevItem) {
                            for (i = this.data.length - 1; i > newsItem.originalIndex; i--) {
                                currentItem = this.data[i];
                                if (newsItem.category == currentItem.category) {
                                    newsItem.prevItem = currentItem;
                                    break;
                                }
                            }
                        }
                    }
                    if (!newsItem.nextItem) {
                        for (i = newsItem.originalIndex + 1; i < this.data.length; i++) {
                            currentItem = this.data[i];
                            if (newsItem.category == currentItem.category) {
                                newsItem.nextItem = currentItem;
                                break;
                            }
                        }
                        if (!newsItem.nextItem) {
                            for (i = 0; i < newsItem.originalIndex; i++) {
                                currentItem = this.data[i];
                                if (newsItem.category == currentItem.category) {
                                    newsItem.nextItem = currentItem;
                                    break;
                                }
                            }
                        }
                    }

                    this.$container.closest('body').addClass('locked newsModalOpen');

                    if (this.getConfig().modalHideNav == true) {
                        this.$container.closest('body').find('.navbarWrap').addClass('hidden').hide();
                    }
                    else {
                        this.$container.closest('body').find('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn');
                    }

                    this.$container.find('.newsDetailContainer').html(templates.newsDetailsModal({
                        item: newsItem,
                        records: this.data
                    }));

                    this.$container.find('.carousel.slide').carousel('pause');

                    setTimeout($.proxy(function () {
                        this.$container.find('.newsDetailContainer').addClass('newsDetailsOpened');
                    }, this), 1000);
                }

                // news scroll

                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(50, util.minScrollSpeed($.proxy(function (event) {
                    if ($('body').hasClass('newsModalOpen')) {

                        var newsTop = $('.newsModalTopCard').position().top;

                        if (newsTop < -200) {
                            // scroll down
                            $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');

                        } else {
                            $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn');
                        }

                    }
                }, this))));

            },

            closeDetails: function () {
                if (this.isReady()) {
                    var newsDetailContainer = $('.newsDetailContainer');
                    $('.newsOverviewContainer').removeClass('fade-out');
                    $('body').removeClass('locked newsModalOpen');
                    newsDetailContainer.removeClass('newsDetailsOpened');
                    setTimeout($.proxy(function () {
                        this.$container.find('.newsDetailContainer').empty();
                    }, this), 1000);
                    if (this.getConfig().modalHideNav == true) {
                        $('.navbarWrap').removeClass('hidden').show();
                    }
                    else {
                        this.$container.closest('body').find('.navbarWrap').addClass('slideUpOut').removeClass('slideDownIn');
                    }
                    this.$container.find('.carousel.slide').carousel('cycle');
                }
            },
        }
    });
});
