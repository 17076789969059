require(['moment','app/user', 'handlebars', 'app/util'],function(moment,user,Handlebars,util) {

    /*
     * Handlebars Helpers
     */

    Handlebars.registerHelper("prev", function (avalues, index, field, options) {
        if (index < 1 || !avalues) {
            return null;
        } else {
            return avalues[index - 1][field];
        }
    });

    Handlebars.registerHelper("math", function (lvalue, operator, rvalue, options) {
        lvalue = parseFloat(lvalue);
        rvalue = parseFloat(rvalue);

        return {
            "+": lvalue + rvalue,
            "-": lvalue - rvalue,
            "*": lvalue * rvalue,
            "/": lvalue / rvalue,
            "%": lvalue % rvalue
        }[operator];
    });

    Handlebars.registerHelper("tickLeft", function (lvalue, rvalue, options) {
        lvalue = parseFloat(lvalue);
        rvalue = parseFloat(rvalue);
        return (lvalue / (rvalue - 1)) * 100 + '%';
    });
    Handlebars.registerHelper("minusOne", function (value) {
        return value - 1;
    });
    Handlebars.registerHelper("plusOne", function (value) {
        return value + 1;
    });
    Handlebars.registerHelper('ifCond', function (v1, v2, options) { // Strict type comparison only, otherwise use below
        if (v1 === v2) {
            return options.fn(this);
        }
        return options.inverse(this);
    });
    Handlebars.registerHelper('ifComp', function (v1, operator, v2, options) {
        switch (operator) {
            case '==':
                return (v1 == v2) ? options.fn(this) : options.inverse(this);
            case '===':
                return (v1 === v2) ? options.fn(this) : options.inverse(this);
            case '<':
                return (v1 < v2) ? options.fn(this) : options.inverse(this);
            case '<=':
                return (v1 <= v2) ? options.fn(this) : options.inverse(this);
            case '>':
                return (v1 > v2) ? options.fn(this) : options.inverse(this);
            case '>=':
                return (v1 >= v2) ? options.fn(this) : options.inverse(this);
            case '&&':
                return (v1 && v2) ? options.fn(this) : options.inverse(this);
            case '||':
                return (v1 || v2) ? options.fn(this) : options.inverse(this);
            case '!=':
                return (v1 != v2) ? options.fn(this) : options.inverse(this);
            default:
                return options.inverse(this);
        }
    });
    Handlebars.registerHelper("rowOpen", function (index_count, mod, block) {
        if (parseInt(index_count) % (mod) === 0) {
            return block.fn(this);
        }
    });
    Handlebars.registerHelper("rowClose", function (index_count, mod, block) {
        if (parseInt(index_count + 1) % (mod) === 0) {
            return block.fn(this);
        }
    });

    Handlebars.registerHelper("bsRow", function (every, context, options) {
        var out = "", subcontext = [], i;
        if (context && context.length > 0) {
            for (i = 0; i < context.length; i++) {
                if (i > 0 && i % every === 0) {
                    out += options.fn(subcontext);
                    subcontext = [];
                }
                subcontext.push(context[i]);
            }
            out += options.fn(subcontext);
        }
        return out;
    });

    //  usage: {{dateFormat creation_date format="MMMM YYYY"}}
    Handlebars.registerHelper('dateFormat', function (context, block) {
        var f = block.hash.format || "MMM Do, YYYY";
        var momentObject = null;
        if(moment.isMoment(context)){
            momentObject = context;
        }
        else{
            momentObject = moment.utc(new Date(context));
        }
        return momentObject.format(f);
    });

    Handlebars.registerHelper('dateCalendar', function (context, isUtc, block) {
        if(isUtc === true) {
            return new moment.utc(new Date(context)).calendar();
        }
        return new moment(new Date(context)).calendar();
    });


    Handlebars.registerHelper ('truncate', function (str, len) {
        if (str.length > len) {
            var new_str = str.substr (0, len+1);

            while (new_str.length) {
                var ch = new_str.substr ( -1 );
                new_str = new_str.substr ( 0, -1 );

                if (ch == ' ') {
                    break;
                }
            }

            if ( new_str == '' ) {
                new_str = str.substr ( 0, len );
            }

            return new Handlebars.SafeString ( new_str+'...' );
        }
        return str;
    });



    Handlebars.registerHelper('if_even', function(conditional, options) {
        if((conditional % 2) == 0) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });
    Handlebars.registerHelper('if_odd', function(conditional, options) {
        if((conditional % 2) == 1) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });

    Handlebars.registerHelper('checkUser', function (role,block) {
        if(user.checkRole(role)) {
            return block.fn(this);
        } else {
            return block.inverse(this);
        }
    });

    Handlebars.registerHelper('if-lt', function (a, b) {
        var options = arguments[arguments.length - 1];
        if (a < b) { return options.fn(this); }
        else { return options.inverse(this); }
    });

    // TODO - We should try to minimize use of hardcoded responsive sizes in templates
    Handlebars.registerHelper("desktop_width", function(block) {
        if (window.innerWidth >= 769) {
            return block.fn(this);
        }
    });

    Handlebars.registerHelper("phone_width", function(block) {
        if (window.innerWidth <= 768) {
            return block.fn(this);
        }
    });

    Handlebars.registerHelper("xs_up", function(block) {
        if (window.innerWidth > 480) {
            return block.fn(this);
        }
    });

    Handlebars.registerHelper("xs_width", function(block) {
        if (window.innerWidth <= 480) {
            return block.fn(this);
        }
    });

    Handlebars.registerHelper("parseYoutube", function(url,block) {
        return util.parseYoutubeId(url);
    });

    Handlebars.registerHelper('reverse', function (arr) {
        arr.reverse();
    });

    Handlebars.registerHelper('formatName', function (name) {
        return new Handlebars.SafeString(name.replace(/-/g,"&#8209;"));
    });

    Handlebars.registerHelper('formatNumber', function (a) {
        return ("0" + a).slice(-2);
    });

});
define("handlebars-helpers", function(){});

