define('modules/social/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates"], function (module, config, $, util, templates) {
    return new module({
        name: 'social',
        selectors: '.socialShare',
        remoteData: [],

        config: {},

        initialize: function () {
            this.renderTemplates();
            this.clickHandlers();

        },

        methods: {

            renderTemplates: function () {
                $(this.getSelectors()).append(templates.social());
            },

            clickHandlers: function () {

                var module = this;

                $(this.getSelectors()).find('.txtWrap').on('click', function () {

                    var $self = $(this),
                        parent = $self.parent('.socialShare');

                    parent.addClass('socialExpanded');
                });

                $(this.getSelectors()).find('.shareSocial').on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var element = $(this);
                    module.onSocialClick(element);
                });
            },

            // This method is scoped to the element itself, not the module class
            onSocialClick: function (element) {

                var loc = location.href,
                    action = element.attr('data-action');

                if (action == 'twitter') {
                    var title = element.attr('data-title') || document.title;
                    window.open('//twitter.com/intent/tweet?url=' + encodeURIComponent(loc) + '&text=' + encodeURIComponent(title), 'twitterwindow', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'facebook') {
                    var t = element.attr('data-title') || document.title;
                    window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(loc) + '&t=' + encodeURIComponent(t), 'sharer', 'status=0,width=626,height=436, top=' + (100) + ', left=' + ($(window).width() / 2 + 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'youtube') {
                    var title = element.attr('data-title') || document.title;
                    window.location.assign("https://www.youtube.com/watch?v=" + title);
                } else if (action == 'google') {
                    window.open('https://plus.google.com/share?url=' + encodeURIComponent(loc), 'Google Share', 'status=0,width=626,height=436, top=' + ($(window).height() / 2 - 225) + ', left=' + ($(window).width() / 2 - 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'linkedin') {
                    var title = element.attr('data-title') || document.title;
                    var lishareText = element.attr('data-title') || document.title;
                    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(loc) + '&title=' + encodeURIComponent(title) + '&summary=' + encodeURIComponent(lishareText), 'Linkedin Share', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                }
            }
        }
    });
});
